
import axios from 'axios'
import { useState } from 'react';
import {Base_url} from '../config/axiosInstance'



 
 const AuthHook=()=>{
      const url=Base_url;
      const formData= new FormData()
      const[response,setResponse]=useState()
      const [loading,setLoading]=useState(false)
      const[error,setError]=useState('')
        
     

       const register=  async (parameter)=>{


          for(let key in parameter){
            formData.append(key,parameter[key])
          }
          setLoading(true)
        try{
         const {data,status}=await axios({
           method:'post',
           url:`${url}user/auth/register`,
           header:{
             'content-type':'application/json'
           },
           data:formData
         })
        //  console.log(status,data.message)
          setResponse(status)
        }
        catch (error){
          // console.log(error.response.data);
          setError(error.response.data.error ?? error.response.data);
        }
        finally{
          setLoading(false)
        }

      }
      const login =async(params)=>{
           setLoading(true)
     
            
           try {
           const  {data,status}= await axios({
               method:'POST',
              url:`${url}user/auth/login`,
              header:{
                'content-type':'application/json'
              },
              data:params
             })
             sessionStorage.setItem('token',data.token)
             setResponse(data)

           } catch (error) {
            // console.log(error);
             sessionStorage.setItem('registerMail', error.response.data.email)
               setError(error.response.data.message);
           }
           finally{
             setLoading(false)
           }



      }

   
      
  
      return {loading,response,error,register,login}
    

}
export default AuthHook;