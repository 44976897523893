import { createSlice } from "@reduxjs/toolkit";

export const errandSlice=createSlice({
    name:'errands',
    initialState:{errandId:''},
    reducers:{
        upDateErrands:(state,action)=>{
            
            state.errandId =action.payload
            
        }
    }
})

export const {upDateErrands}=errandSlice.actions 
export default errandSlice.reducer