import React from 'react';

function Cancel({onClick}) {
    return (
        <svg onClick={onClick} width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5263 8.90113C20.7273 8.69498 20.7273 8.36075 20.5263 8.15461C20.3252 7.94846 19.9993 7.94846 19.7983 8.15461L14.5 13.5879L9.2017 8.15461C9.00068 7.94846 8.67476 7.94846 8.47373 8.15461C8.27271 8.36075 8.27271 8.69498 8.47373 8.90113L13.772 14.3344L8.15077 20.0989C7.94974 20.305 7.94974 20.6392 8.15077 20.8454C8.35179 21.0515 8.67771 21.0515 8.87874 20.8454L14.5 15.0809L20.1213 20.8454C20.3223 21.0515 20.6482 21.0515 20.8492 20.8454C21.0503 20.6392 21.0503 20.305 20.8492 20.0989L15.228 14.3344L20.5263 8.90113Z" fill="#74747E"/>
        </svg>
        
    );
}

export default Cancel;