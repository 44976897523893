import { createContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.scss";
import MainApp from "./components/mainApp";
import AuthPage from "./pages/AuthPage";
import Login from "./pages/AuthPage/Login";
import NewLogin from "./pages/AuthPage/NewLogin";
import Reset from "./pages/AuthPage/Reset";
import Verify from "./pages/AuthPage/VerifyAccount";
// import {firebase} from './firebase';
import Landing from "./pages/Landing";

import useAxios from "./config/useAxios";
import Protected from "./config/protected";
import Tandc from "./tandc";
import Help from "./pages/Help";
import Kyc from "./pages/KYC";
function App() {
  const [errand,setErrand]=useState({})
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/kyc" element={<Kyc />} />
        <Route exact path="/tandc" element={<Tandc />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path='help' element={<Help />} />

        <Route path="/login" element={<Login />} />
        <Route path="/new_login" element={<NewLogin />} />
        <Route path="/verify/:email" element={<Verify />} />
        <Route path="/reset/*" element={<Reset />} />
       <Route path="/*" element={ <Context.Provider value={[errand,setErrand]}><MainApp /></Context.Provider>} />
      </Routes>
    </div>
  );
}

export default App;

export const Context = createContext()
