import React from 'react';

function Send(props) {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.3607 17.0122C30.37 16.2955 29.9817 15.6368 29.3513 15.291L14.6594 7.21888C14.0014 6.84697 13.2256 6.89266 12.6056 7.31608C11.9741 7.74664 11.6509 8.77158 11.8274 9.51184L13.199 15.2581C13.3398 15.8473 13.8671 16.2622 14.4738 16.2596L22.6498 16.2343C23.0685 16.2259 23.4081 16.5654 23.3997 16.9841C23.3984 17.3957 23.0638 17.7302 22.6452 17.7386L14.4614 17.7576C13.8547 17.7588 13.3247 18.1756 13.1803 18.7657L11.7602 24.5348C11.5878 25.2165 11.7844 25.8971 12.2654 26.3782C12.322 26.4348 12.3857 26.4984 12.4494 26.5479C13.0723 27.0285 13.8882 27.0898 14.5847 26.7187L29.3264 18.7255C29.959 18.3901 30.3513 17.7289 30.3607 17.0122Z" fill="#74747E"/>
</svg>

    );
}

export default Send;