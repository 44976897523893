import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import email from '../../assets/png/email.png'
import LeftArrow from '../../assets/svg/LeftArrow'
import Button from '../../components/Button'
import useAxios from '../../config/useAxios'
import axios from '../../config/axiosInstance'
import axiosInstance from '../../config/axiosInstance'
import hands from '../../assets/png/hands.png'
import { ToastContainer, toast } from 'react-toast'


export default function Verify(){
  
    const [userInput, setUserInput,] = useState(new Array(5).fill(''))
    
    const mail=sessionStorage.getItem('registerMail')

   const[otpEntered,setOtp]=useState(false)
   const [response,error,fetchData,loading]=useAxios()
   useEffect(()=>{if(response&&otpEntered){
       navigate('/login')
   }},[response])

   const verify=()=>{
       setOtp(true)
       fetchData({
           method:'POST',
           url:'user/auth/verify-otp',
           axiosInstance:axios,
           requestConfig:{ 
                   otp:userInput.join('')
               }         

       })
   }
  
   
   const resendOtp=()=>{
     if(countDown==0){
       fetchData({
           method:'GET',
           url:`user/auth/re-generate-otp?email=${mail}`,
           axiosInstance:axios
       }) 
     }  
     else toast.success("Please wait , OTP is on the way")
   }
   const navigate =useNavigate()
   
    const countDownDate = new Date(sessionStorage.getItem('sentTime')).getTime()  + (300*1000);
    // console.log(countDownDate);
    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            if (new Date().getTime()< countDownDate)setCountDown(countDownDate - (new Date().getTime()));
            else setCountDown(0)
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);
    // console.log( new Date().getTime() , countDownDate);
    // return getReturnValues(countDown);
   const width= window.innerWidth
   return width<600?(
    <>
           <ToastContainer position="top-center" delay={'5000'} />
           <div className="auth verify">
               <div className="top">
                   <LeftArrow onClick={() => navigate(-1)} />
               </div>
               <div className="instruction">
                   <h3>
                       Verify email
                   </h3>
                   <p>
                       An email with a verification code has been sent to <span className="mail">{mail}</span>.  Please enter the code to verify your account.
                   </p>
               </div>
               {response && <span>{response.message}</span>}
               {error && <span style={{ color: 'red', textAlign: 'center' }}>{error.message}</span>}

               <div class='img'><img src={email} alt="" /></div>
               <form action="">

                   {userInput.map((input, idx) => (
                       // @ts-ignore
                       <input key={idx} maxLength={1} value={userInput[idx]} style={{ textAlign: 'center' }} onPaste={async (e) => {
                           const board = await navigator.clipboard.readText()
                           if (board.length === 5) setUserInput([...board.split('')])
                       }
                       } onChange={(e) => {
                           // @ts-ignore
                           if (e.target.value && e.target.nextSibling) e.target.nextSibling.focus()

                           setUserInput([...userInput.map((t, id) => id === idx ? e.target.value : t)])
                       }} type='number' />
                   ))}
               </form>

               <div className="link">Didnt recieve code? you can click <Link to='' onClick={resendOtp}>Resend</Link>  {countDown > 0 && `in ${getReturnValues(countDown)[0]}:${getReturnValues(countDown)[1]}`}</div>
               <Button disabled={loading ? true : false} content={loading ? 'Please wait' : 'Verify email'} onClick={(e) => {
                   e.preventDefault()
                   verify()
               }} />
           </div>
    </>
   
    ):(
        <>
         <ToastContainer position="top-center" delay={'5000'} /> 
         <div className="big_auth">
        <div className="auth verify">
            <div className="top">
                <LeftArrow onClick={()=>navigate(-1)} />
            </div>
            <div className="instruction">
                <h3>
                  Verify email
                 </h3>
                <p>
                           An email with a verification code has been sent to <span className="mail">{mail }</span>.  Please enter the code to verify your account.
                </p>
            </div>
            {response &&<span>{response.message}</span>}
            {error &&<span style={{color:'red',textAlign:'center'}}>{error.message}</span>}

            <div class='img'><img src={email} alt="" /></div>
            <form action="">
                       {userInput.map((input, idx) => (
                           // @ts-ignore
                           <input maxLength={1} value={userInput[idx]} style={{ textAlign: 'center' }} onPaste={async (e) => {
                               const board = await navigator.clipboard.readText()
                               if (board.length === 5) setUserInput([...board.split('')])
                           }
                           } onChange={(e) => {
                               // @ts-ignore
                               if (e.target.value && e.target.nextSibling) e.target.nextSibling.focus()

                               setUserInput([...userInput.map((t, id) => id === idx ? e.target.value : t)])
                           }} type='number' />
                       ))}
            </form>
                   <div className="link">Didnt recieve code? you can click<Link to='' onClick={resendOtp}>Resend</Link> {countDown>0 &&  `in ${getReturnValues(countDown)[0]}:${getReturnValues(countDown)[1]}` }</div>
                   <Button disabled={loading ? true : false} content={loading ? 'Please wait' : 'Verify email'} onClick={(e) => {
                       e.preventDefault()
                       verify()
                   }} />
               </div>
        <div className="image">
            <img src={hands} alt="" />
        </div>
        </div>
        </>
       
    )
   
}


const getReturnValues = (countDown) => {
    // calculate time left
    // const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    // const hours = Math.floor(
    //     (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    // );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [ minutes, seconds];
};