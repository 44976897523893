import React, { useRef } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import './index.scss'
// import User from '../../assets/png/testImage.png'
import LeftArrow from '../../assets/svg/LeftArrow';
import Profile from '../../assets/svg/Profile';
// import History from '../../assets/svg/History';
import Settings from './Settings';
import Edit from './Edit';
import { useSelector } from 'react-redux';
import Myerrands from './Myerrands';
import Wallet from './Wallet';
import Fund from './Fund';
import Withdraw from './withdraw';




function UserProfile(props) {
    const user=useSelector(state=>state.user)
    const navigate = useNavigate()
   
    const width =window.innerWidth

    return width<600? (
     <div className="profile">
          <div className="top" >
                <LeftArrow onClick={() => navigate(-1)} />
                <div className="avatar">
                    <img src={user.value.avatar} alt="" />
                </div>
                 <div className="name_username">
                    <h4>
                        {user.value.fullName}
                    </h4>
                    <span>
                        {user.value.username}
                    </span>
                </div>
               
            </div>
            <div className="body">
        <Routes>
            <Route path='' element={<Index />} />
            <Route path='settings' element={<Settings/>} />
            <Route path='edit' element={<Edit />} />
            <Route path='errands/*' element={<Myerrands/>} />
            <Route path='wallet' element={<Wallet/>} />
            <Route path='fund-wallet' element={<Fund/>} />
            <Route path='withdraw' element={<Withdraw/>} />
            
        </Routes>
        </div>
        </div>
    ):(
        <div className="profile">
            <div className="options">
               <Index />
          </div>
        <div className="body">
        <Routes>
        
            <Route path='settings' element={<Settings/>} />
            <Route path={''||'edit'} element={<Edit />} />
            <Route path='errands/*' element={<Myerrands/>} />
            <Route path='wallet' element={<Wallet/>} />
            <Route path='fund-wallet' element={<Fund/>} />
            <Route path='withdraw' element={<Withdraw/>} />
            
        </Routes>
        </div>
        </div>
    );
}

export default UserProfile;


const data=[{
    icon:<Profile fill={'none'}/>,
    head:'Edit Profile',
    link:'edit',
    writeUp:'Edit your account and personal details'
}
// ,{
//     icon:<History/>,
//     head:'Transaction History',
//     link:'Transactions',
//     writeUp:'See payments you have made or received'

// }
,{
    icon:<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 11.65H7" stroke="#5D5FEF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 11.6501V7.03009C2 4.99009 3.65 3.34009 5.69 3.34009H11.31C13.35 3.34009 15 4.61009 15 6.65009" stroke="#5D5FEF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.48 12.7C16.98 13.18 16.74 13.92 16.94 14.68C17.19 15.61 18.11 16.2 19.07 16.2H20V17.65C20 19.86 18.21 21.65 16 21.65H6C3.79 21.65 2 19.86 2 17.65V10.65C2 8.44002 3.79 6.65002 6 6.65002H16C18.2 6.65002 20 8.45002 20 10.65V12.1H18.92C18.36 12.1 17.85 12.32 17.48 12.7Z" stroke="#5D5FEF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.0002 13.12V15.18C22.0002 15.74 21.5402 16.2 20.9702 16.2H19.0402C17.9602 16.2 16.9702 15.41 16.8802 14.33C16.8202 13.7 17.0602 13.11 17.4802 12.7C17.8502 12.32 18.3602 12.1 18.9202 12.1H20.9702C21.5402 12.1 22.0002 12.56 22.0002 13.12Z" stroke="#5D5FEF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    ,
    head:'My wallet',
    link:'wallet',
    writeUp:'See details of your wallet and the amount you can withdraw'
}
,
{
    icon:<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.46973 9.5C7.40272 9.5 8.96973 7.933 8.96973 6C8.96973 4.067 7.40272 2.5 5.46973 2.5C3.53673 2.5 1.96973 4.067 1.96973 6C1.96973 7.933 3.53673 9.5 5.46973 9.5Z" stroke="#5D5FEF" stroke-width="1.5"/>
    <path d="M16.9697 15.5H19.9697C21.0697 15.5 21.9697 16.4 21.9697 17.5V20.5C21.9697 21.6 21.0697 22.5 19.9697 22.5H16.9697C15.8697 22.5 14.9697 21.6 14.9697 20.5V17.5C14.9697 16.4 15.8697 15.5 16.9697 15.5Z" stroke="#5D5FEF" stroke-width="1.5"/>
    <path d="M11.9997 5.5H14.6797C16.5297 5.5 17.3897 7.79 15.9997 9.01L8.0097 16C6.6197 17.21 7.4797 19.5 9.3197 19.5H11.9997" stroke="#5D5FEF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.48573 6H5.49728" stroke="#5D5FEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.4857 19H18.4973" stroke="#5D5FEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
    ,
    head:'My Errands',
    link:'errands',
    writeUp:'Track, edit and delete errands you have posted'
},{
    icon:<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z" stroke="#5D5FEF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 13.3801V11.6201C2 10.5801 2.85 9.72006 3.9 9.72006C5.71 9.72006 6.45 8.44006 5.54 6.87006C5.02 5.97006 5.33 4.80006 6.24 4.28006L7.97 3.29006C8.76 2.82006 9.78 3.10006 10.25 3.89006L10.36 4.08006C11.26 5.65006 12.74 5.65006 13.65 4.08006L13.76 3.89006C14.23 3.10006 15.25 2.82006 16.04 3.29006L17.77 4.28006C18.68 4.80006 18.99 5.97006 18.47 6.87006C17.56 8.44006 18.3 9.72006 20.11 9.72006C21.15 9.72006 22.01 10.5701 22.01 11.6201V13.3801C22.01 14.4201 21.16 15.2801 20.11 15.2801C18.3 15.2801 17.56 16.5601 18.47 18.1301C18.99 19.0401 18.68 20.2001 17.77 20.7201L16.04 21.7101C15.25 22.1801 14.23 21.9001 13.76 21.1101L13.65 20.9201C12.75 19.3501 11.27 19.3501 10.36 20.9201L10.25 21.1101C9.78 21.9001 8.76 22.1801 7.97 21.7101L6.24 20.7201C5.33 20.2001 5.02 19.0301 5.54 18.1301C6.45 16.5601 5.71 15.2801 3.9 15.2801C2.85 15.2801 2 14.4201 2 13.3801Z" stroke="#5D5FEF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>  
    ,
    head:'Settings',
    link:'settings',
    writeUp:'Select your preferences for better experience'
}]
const Index=()=>{

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    return(
        <>
          {data.map(data=>(
              <ProfileTab props={data}/>
          ))}
        </>
    )
}

const ProfileTab=({props})=>{
    const link=useRef(null)

    return(
        <div className="profile_tab" onClick={()=>link.current.click()}>
            <Link ref={link} to={props.link} style={{display:'none'}}></Link>
        <div className="icon">
           {props.icon}
        </div>
        <div className="write_up">
           <h4>
               {props.head}
           </h4>
           <p>
              {props.writeUp}
           </p>
        </div>
        <div className="angle_brace">
        <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.6665 0.833292L6.33317 5.49996L1.6665 10.1666" stroke="#AEAEB9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

        </div>
    </div>
    )
}