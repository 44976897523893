import React from 'react';
import Dm from '../../assets/svg/Dm';
import LeftArrow from '../../assets/svg/LeftArrow';
import Locate from '../../assets/svg/locate';
import Search from '../../assets/svg/Search';
import User from '../../assets/png/testImage.png'
import { useNavigate } from 'react-router-dom';

function OnLine(props) {
    const navigate=useNavigate()
    return (
        <div class='auth online'>
            <div className="top">
                <LeftArrow onClick={() => navigate(-1)} />
            </div>
            <div className="instruction">
                <h3>
                    Online helpers
                </h3>
                <p>
                Click a helper to send message
                </p>
            </div>
            <form action="">
            <div className="carries_svg">
                <input type="text" placeholder='Search by name'/>
                <div className="svg">
                    <Search />
                </div>
            </div>
            </form>

            <div className="online_helpers">
                <OnlineUser />
                <OnlineUser />
                <OnlineUser />
                <OnlineUser />
            </div>
        </div>
    );
}

export default OnLine;

function OnlineUser(){
    return(
        <div className="online_user">
            <div className="user_detail">
               <div className="avatar">
                      <img src={User} alt="" />
                  </div>
                  <div className="name_location">
                      <h4>
                         name <span>username</span>
                      </h4>
                      <p>
                          <Locate /> location
                      </p>
                  </div>
            </div>
            <div className="message_icon">
                   <Dm />
            </div>
        </div>
    )
}