import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams} from 'react-router-dom';
import Cancel from '../../assets/svg/Cancel';
import LeftArrow from '../../assets/svg/LeftArrow';
import Button from '../../components/Button';
// import OnLine from './onLine'
import useAxios from '../../config/useAxios';
import { useDispatch } from 'react-redux';
import {upDateErrands} from '../../utils/features/errands'
import { useSelector } from 'react-redux';
import { Context } from '../../App';
import { ToastContainer, toast } from 'react-toast';

function Post({head,button,requestParams}) {
    const [success,setSuccess]=useState(false)
    // const [onLine,setOnline]=useState(false)
    const [postResponse,postError,postData,postLoading]=useAxios()
    const navigate=useNavigate()
    const {userId}=useParams()
    const dispatch= useDispatch()
    const locations= useSelector(state=>state.map)

    // const [postingParams,setParams]=useState({
    //     errandNote:'',
    //     currentLocation:{
    //         type:'Point',
    //         coordinates:[locations?.yourLocation.lng,locations?.yourLocation.lat]
    //     },
    //     errandLocation:{
    //         type:'Point',
    //         coordinates:[locations.errandLocation.lng, locations.errandLocation.lat]
    //     },
        
    // })
    
    useEffect(()=>{
        if(postResponse){
        if(userId){
            dispatch(upDateErrands(postResponse.id))
            navigate(-1)
        }
       else setSuccess(true)}},[postResponse])
    const postErrand=()=>{  
        if(errand.innitialOffer>0){
            postData({
           ...requestParams,
            requestConfig:userId?{
              ...errand,
                currentLocation: {
                    type: 'Point',
                    coordinates: [locations?.yourLocation.lng, locations?.yourLocation.lat]
                },
                errandLocation: {
                    type: 'Point',
                    coordinates: [locations.errandLocation.lng, locations.errandLocation.lat]
                },
              status:'0'
            }: {
                ...errand,
                    currentLocation: {
                        type: 'Point',
                        coordinates: [locations?.yourLocation.lng, locations?.yourLocation.lat]
                    },
                    errandLocation: {
                        type: 'Point',
                        coordinates: [locations.errandLocation.lng, locations.errandLocation.lat]
                    }
            }
        })  
        }    
        else toast.error('Errand offer cant be 0')   
         
    }
    const [errand,setErrand]=useContext(Context)
    return (
        <>
            <ToastContainer position="top-center" delay={'5000'} /> 
            <div className="top">
                <LeftArrow onClick={() => navigate(-1)} />
            </div>
            <div className="instruction">
                <div class='instruction_top'>
                    <h3>
                      {head}
                   </h3>
                  {/* {!userId && <span onClick={()=>setOnline(true)}> */}
                      {/* View online helpers  */}
                   {/* </span>} */}
                </div>
                <p>
                Describe your errand, enter offer price and use the map to indicate your location and location of the errand
                </p>
            </div>
            {postError && <p style={{width:'100%',textAlign:'center',color:'red',margin:'0'}}>{postError.message}</p>}
            <form  action="">
                <textarea value={errand?.errandNote}  id="" cols="30" rows="10" placeholder='I need someone to ...' name='errandNote'  onChange={(e)=>setErrand({...errand,[e.target.name]:e.target.value})}>

                </textarea>
                <input value={errand?.innitialOffer} type="number" placeholder='offer price' name='innitialOffer' onChange={(e) => {
                    
                     setErrand({ ...errand, [e.target.name]: e.target.value }) 
                }} />

                <div className="instruction">
                    <h3>
                        Select location
                    </h3>
                    <div className="locations">
                    <Link to='/get_help/location' className="checkin">
                      
                      Select Locations                   
                       </Link>
                       {
                       locations?.yourLocation?.description &&<div>
                        <li><b>delivery Location-</b> {locations?.yourLocation?.description}</li>
                        <li><b>Errand Location-</b> {locations?.errandLocation?.description}</li>
                       </div>
                       }
                    </div>
                </div>
                <Button   onClick={(e)=>{
                e.preventDefault()
                postErrand()
            }} content={postLoading?'Please wait':button} />
            </form>
            {success&&  <div className="alert">
             <article>
                <div className="heading">
                                   <div  >
                <Cancel  onClick={()=>navigate(-1)}/>
            </div>
                    <h4 >
                      Errand Successfully posted
                    </h4>

        </div> 
        <div className="message">
        You have successfully posted errand, we will let you know when someone is interested
        </div>
        <span className="checkin" onClick={()=>navigate('/profile/errands')}>
               view errands
        </span>
    </article>
    </div>
    }
    {/* {
        onLine && <div class='alert'> <OnLine /></div>
    } */}
    
           
        </>
    );
}

export default Post;