import { useState } from "react"
import Cancel from "../../assets/svg/Cancel"
import Test from '../../assets/png/testImage.png'
import useAxios from "../../config/useAxios"
import _protected from "../../config/protected"
import Protected from "../../config/protected"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import Utility from "../../utils/messages/utils"


export default function ErrandCard({user}){

    const [bid,setBid]=useState(false)
    const biddings = useSelector((state) => state.bid);
    const { getBid} = Utility()
 
 
     const variables=user
     const [success,setSuccess]=useState(false)
    
     const [response,error,fetchData,loading]=useAxios()
     const [bidPrice,setBidPrice]=useState()
     const placeBid=()=>{

        fetchData({
            method:'POST',
            url:`errands/${variables.id}/bid`,
            axiosInstance:Protected(sessionStorage.getItem('token')),
            requestConfig:{
                  price:bidPrice
            }

        })
     }
     const [acceptRes,acceptError,acceptErrand,accLoading]=useAxios()
     const AcceptBid = () => {
        acceptErrand({
          method: "POST",
          url: `errands/${variables.id}/accept`,
          axiosInstance: Protected(sessionStorage.getItem("token")),
        });
      };
     useEffect(()=>{
        if(response)setSuccess(true)
     },[response])


     const navigate= useNavigate()
     useEffect(()=>{
        if(acceptRes){
            getBid(biddings.activeBids.type)
            navigate('/negotiations')
        }
     },[acceptRes])
     return(
         
       
     <div class='errand'>
         {bid &&<div className="bid">
           {!success &&  <div className="bid_details">
                 <div className="bid_top">
                     <span>
                         <h4>
                             Details
                         </h4>
                         <span onClick={()=>navigate(`/map/${variables.id}`)} className="view_map">
                            View map
                         </span>
                     </span>
                     <span onClick={()=>setBid(false)} className="cancel">
                        <Cancel />
                     </span>
                 </div>
                 <div className="errand_description">
                     <p class='description'>
                         <span class='tag'>{`@${variables.user.username} `}</span>{variables.errandNote}
                     </p>
                     <p class='description'>
                         <span className="tag">{`@${variables.user.username}`}</span> offers to pay {variables.innitialOffer} NGN. If you are not okay with the offer, enter your price to negotiate and place a bid. 
                   </p>
                      <p class='description'>If you are okay with the offer, just place a bid.
                     </p>
                 </div>
                 <div className="enter_price">
                     <h4>Enter your price</h4>
                     <span>
                         <input type="tel" placeholder='Enter price' onChange={(e)=>setBidPrice(e.target.value)}  />
                         <span onClick={()=>bidPrice?placeBid():''}class='checkin'>
                             Place bid
                         </span>
                     </span>
                 </div>
             </div>
}
{
    success && <article>
       <div className="heading">
           <h4 >
               Bid successfully placed
            </h4>
            <div onClick={()=>{
                setBid(false)
                setSuccess(false)
            }}>
                <Cancel />
            </div>
        </div> 
        <div className="message">
        You have successfully placed a bid to run this errand.
        </div>
        <span className="checkin">
               view transaction
        </span>
    </article>
}
         </div>}
            <div className="errand_user_details">
                <div className="avatar">
                   <img src={variables.user.avatar} alt='' />
                </div>
                <div className="name_username">
                    <h4 style={{textTransform:'capitalize'}}>
                          {variables.user.fullName}
                    </h4>
                    <span className="username">
                      {`@${variables.user.username}`}
                    </span>
                </div>
            </div>
            <div className="errand_description">
                <p className="description">
                     {variables.errandNote}
                </p>
                <span className="offer">
                   Offer:{variables.innitialOffer}N
                </span>
                {acceptError && <p style={{fontSize:'12px',color:'red'}}>{acceptError.message}</p>}
                <div className="help_location">
                    <span onClick={AcceptBid} className="checkin">
                         {accLoading?'loading':'help'}
                    </span>
                    <span  onClick={()=>setBid(true)} className="locate">
                        See more
                       <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M8.00001 8.95334C9.14877 8.95334 10.08 8.02209 10.08 6.87334C10.08 5.72458 9.14877 4.79333 8.00001 4.79333C6.85126 4.79333 5.92001 5.72458 5.92001 6.87334C5.92001 8.02209 6.85126 8.95334 8.00001 8.95334Z" stroke="#5D5FEF" stroke-width="1.5"/>
                         <path d="M2.41333 5.66C3.72667 -0.113337 12.28 -0.10667 13.5867 5.66666C14.3533 9.05333 12.2467 11.92 10.4 13.6933C9.06 14.9867 6.94 14.9867 5.59333 13.6933C3.75333 11.92 1.64667 9.04666 2.41333 5.66Z" stroke="#5D5FEF" stroke-width="1.5"/>
                       </svg>
 
                    </span>
                </div>
            </div>
        
        
        
          </div>
          
     )
 }