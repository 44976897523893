import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Cancel from '../../assets/svg/Cancel';
import LeftArrow from '../../assets/svg/LeftArrow';
import Button from '../../components/Button';
import OnLine from '../GetHelp/onLine';
import useAxios from '../../config/useAxios';

import { useSelector } from 'react-redux';
import Protected from '../../config/protected';
import { toast } from 'react-toast';

function Post() {
    const {errandId}=useParams()
    
    const [success,setSuccess]=useState(false)
    const [onLine,setOnline]=useState(false)
    const [postResponse,postError,postData,postLoading]=useAxios()
    const navigate=useNavigate()
    const [postingParams,setParams]=useState({
        errandNote:'',
        id:errandId,
        currentLocation:{
            type:'Point',
            coordinates:[6.66476510219063, 3.3526743954507634]
        },
        errandLocation:{
            type:'Point',
            coordinates:[6.66476510219068, 3.3526743954507636]
        },
        
    })
    const errand=useSelector(state=>state.singleErrand)
    useEffect(()=>postResponse?setSuccess(true):'')
    const postErrand=()=>{  
                
        postData({
           method:'PUT',
           url:'errands',
           axiosInstance:Protected(sessionStorage.getItem('token')),
            requestConfig:{
               ...postingParams
            }
        })   
    }
    
    return (
        <>
            <div className="top">
                <LeftArrow onClick={() => navigate(-1)} />
            </div>
            <div className="instruction">
                <div class='instruction_top'>
                    <h3>
                      Edit errand
                   </h3>
                   {/* <span onClick={()=>setOnline(true)}>
                      View online helpers 
                   </span> */}
                </div>
                <p>
                Describe your errand, enter offer price and use the map to indicate your location and location of the errand
                </p>
            </div>

            <form action="">
                <textarea   id="" cols="30" rows="10"  name='errandNote' placeholder={errand?.value.errandNote} onChange={(e)=>setParams({...postingParams,[e.target.name]:e.target.value})}>

                </textarea>
                <input type="number" value={errand?.value.innitialOffer} placeholder='offer price' name='innitialOffer' onChange={(e)=>{
              
                   setParams({...postingParams,[e.target.name]:e.target.value})}}/>

                <div className="instruction">
                    <h3>
                        Select location
                    </h3>
                    <div className="locations">
                    <Link to='location' className="checkin">
                      
                      Select Locations                   
                       </Link>
                    </div>
                </div>
                <Button   onClick={(e)=>{
                e.preventDefault()
                postErrand()
            }} content={'Confirm'} />
            </form>
            {success&&  <div className="alert">
               <article>
                <div className="heading"> 
                <div >
                <Cancel onClick={()=>navigate(-1)} />
            </div>
                    <h4 >
                      Errand Successfully posted
                    </h4>
              
        </div> 
        <div className="message">
        You have successfully posted errand, we will let you know when someone is interested
        </div>
        <span onClick={()=>navigate('/profile/errands')} className="checkin">
               view errands
        </span>
    </article>
       </div>}
    {
        onLine && <div className="alert"> <OnLine />
    
            </div>}
           
        </>
    );
}

export default Post;;