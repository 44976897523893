import axios from 'axios'
import {  Base_url } from './axiosInstance'

  const Protected=(token)=>{
const Baseurl=Base_url

return axios.create({
    baseURL:Baseurl,
    headers:{
        'content-type':'application/json',
        'Authorization':`Bearer ${token}`
    }
})
  }

  export default Protected