import React from 'react';

function Locate(props) {
    return (
        <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.53162 0.936771C5.71648 -0.332726 8.40203 -0.310537 10.5664 0.994894C12.7095 2.32691 14.012 4.70418 13.9998 7.26144C13.95 9.80194 12.5533 12.19 10.8075 14.0361C9.79983 15.1064 8.67261 16.0528 7.44884 16.856C7.32281 16.9289 7.18475 16.9777 7.04148 17C6.9036 16.9941 6.76932 16.9534 6.65075 16.8814C4.78243 15.6746 3.14334 14.134 1.81233 12.334C0.698589 10.8314 0.0658357 9.01601 1.16173e-06 7.13442C-0.00144468 4.57225 1.34677 2.20627 3.53162 0.936771ZM4.79416 8.19478C5.16168 9.10084 6.02918 9.69184 6.9916 9.69185C7.6221 9.69638 8.22819 9.44383 8.67481 8.99048C9.12143 8.53712 9.37148 7.92064 9.36925 7.27838C9.37261 6.29804 8.79546 5.41231 7.90726 5.03477C7.01907 4.65723 5.99504 4.86235 5.31332 5.55435C4.63159 6.24635 4.42664 7.28872 4.79416 8.19478Z" fill="#AEAEB9"/>
<ellipse opacity="0.4" cx="7" cy="19" rx="5" ry="1" fill="#AEAEB9"/>
</svg>

    );
}

export default Locate;