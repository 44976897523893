import "./index.scss";
import { useEffect, useRef, useState } from "react";
import ErrandCard from "./ErrandCard";
import Search from "../../assets/svg/Search";
import useAxios from "../../config/useAxios";
import axios from "../../config/protected";
import { useDispatch, useSelector } from "react-redux";
import Empty1 from "../../assets/png/Empty1.png";

import { Bars } from "react-loading-icons";
import Protected from "../../config/protected";
import { enterUser } from "../../utils/features/user";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from '../../assets/png/purpleLogo.png'
import EmptyState from "../../components/EmptyState";
export default function Home() {
  const [errands, setErrands] = useState([]);
  const {value} = useSelector(state => state.user)
  
  const [errandresponse, errandError, fetchErrand, loading] = useAxios();
  const [params, setParams] = useState({ page: 1, limit: 10, totalPages: "" });


  useEffect(() => {
     
      fetchErrand({
        method: "GET",
        url: `errands?limit=${params.limit}&page=${params.page}`,
        axiosInstance: Protected(sessionStorage.getItem('token')),
      });
   
      // 👇️ scroll to top on page load
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

      return ()=>setReload(false)
  
  }, [params.page]);




  const [reload, setReload] = useState(false)



  



  useEffect(() => {
    if (reload && !loading) { if (params.page < errandresponse?.totalPages) setParams({...params,page:params.page + 1}) }
    return () => setReload(false)
  }
    , [reload])

 



  const setData = () => {
    setErrands([...errands, ...errandresponse?.results]);
  };
  
  useEffect(() => (errandresponse?.results.length>0 ? setData() : ""), [errandresponse]);

  // use to load more on scroll
  const interDemo = useRef(null);
  const callBack = (entries) => {


    const [entry] = entries;
   

    if (entry.isIntersecting) {
   
      setReload(true)
    }

  };
  let options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.3,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callBack, options);
    const target = interDemo.current;
    if (target) observer.observe(target);

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [interDemo]);

  return (
    <div className="home">
      {!value?.verified&&<NotVerified />}
      <Top head={"Discover"} />

      <section class="errands">
        {!loading&&errands.map((errand) => (
          <ErrandCard key={errand.id} user={errand} />
        ))}
        {
          (!loading && !errands.length)&& (<>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'80vh',flexDirection:'column',gap:'24px'}} class='empty_state'>
              
              
              <div className="img">
                <img src={Empty1} alt='' />
              </div>
              <div className="instruction">
                <h3>
                  This Place looks empty
                </h3>


              </div>
            
            </div>
          
          </>)
        }
        <div className="loadmore" ref={interDemo}></div>
      </section>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "48px",
          }}
        >
          <Bars />
        </div>
      )}
    </div>
  );
}

function NotVerified() {
   const navigate = useNavigate()
  // const [response, verifyerror, verify, loading] = useAxios();
    // const product = "basic_kyc" 
    const handleVerify = async () => {
    //  // use the actual verification u would like to use in the platform and not this, also try to use something with some facial checks
      navigate("/kyc")
      // verify({
      //   method:'get',
      //   url:'user/verify/token?type='+product,
      //   axiosInstance:axios(
      //      sessionStorage.getItem('token')
      //   ),
       
      // })
     
    }
  const dispatch = useDispatch();
  

     
  
   
    // },[response])
  return (
    <article className="article" onClick={handleVerify}>
      <span>
        {" "}
        Your account is not yet verified.<b>click to proceed</b> with verification
      </span>
      <span>
        <svg
          width="10"
          height="12"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.63559 2.69317C9.79022 2.5346 9.79022 2.2775 9.63559 2.11893C9.48096 1.96036 9.23025 1.96036 9.07561 2.11893L5 6.29837L0.924387 2.11893C0.769753 1.96036 0.519043 1.96036 0.364409 2.11893C0.209776 2.2775 0.209776 2.5346 0.364409 2.69317L4.44002 6.87262L0.115975 11.3068C-0.0386583 11.4654 -0.0386584 11.7225 0.115975 11.8811C0.270609 12.0396 0.521319 12.0396 0.675952 11.8811L5 7.44686L9.32405 11.8811C9.47868 12.0396 9.72939 12.0396 9.88402 11.8811C10.0387 11.7225 10.0387 11.4654 9.88402 11.3068L5.55998 6.87262L9.63559 2.69317Z"
            fill="black"
          />
        </svg>
      </span>
    </article>
  );
}
export function Top({ head }) {
  return (
    <section>
      <h2>{head} Errands</h2>
      <div className="search">
        {/* <div class="carries_svg">
          <input type="text" placeholder="Search errands" />
          <div className="svg">
            <Search />
          </div>
        </div> */}
        {/* <div className="filter">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M10.1172 17.9866H2.88306"
              stroke="#020317"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.1179 17.9866C21.1179 19.5772 19.8285 20.8666 18.2379 20.8666C16.6473 20.8666 15.3579 19.5772 15.3579 17.9866C15.3579 16.3948 16.6473 15.1066 18.2379 15.1066C19.8285 15.1066 21.1179 16.3948 21.1179 17.9866Z"
              stroke="#020317"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              opacity="0.4"
              d="M13.8827 6.26212H21.118"
              stroke="#020317"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.88259 6.26212C2.88259 7.85388 4.172 9.14212 5.76259 9.14212C7.35318 9.14212 8.64259 7.85388 8.64259 6.26212C8.64259 4.67153 7.35318 3.38212 5.76259 3.38212C4.172 3.38212 2.88259 4.67153 2.88259 6.26212Z"
              stroke="#020317"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div> */}
      </div>
    </section>
  );
}
