import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cancel from '../../assets/svg/Cancel';
import Protected from '../../config/protected';
import _protected from '../../config/protected';
import useAxios from '../../config/useAxios';
import {loadErrand} from '../../utils/features/errand'

function ErrandCard({errand}) {
    const dispatch=useDispatch()
    const user=useSelector(state=>state.user)
    const [option,setOption]=useState(false)
    // console.log(errand);
    let time= new Date(errand.updatedAt)
    let currentTime= new Date()
    let diff=currentTime-time
    const[updateTime,setTime]=useState({
         seconds:diff/1000,
         minutes:(diff/1000)/60,
         hours:(diff/1000)/3600,
         days:((diff/1000)/3600)/24
    })
    const {hours,days,seconds,minutes}=updateTime
    const displayTime=()=>{
        if(Math.floor(days)>0){
            return `${Math.floor(days)}d`
        }
        else if(Math.floor(hours)>0){
            return `${Math.floor(hours)}h`
        }
        else if(Math.floor(minutes)>0){
            return `${Math.floor(minutes)}m`
        }
        else return `${Math.floor(seconds)}s`
    }
    const navigate=useNavigate()
    const [response,error,deleteData,loading]=useAxios()

    const del=()=>{
        deleteData({
            method:'DELETE',
            url:`errands?errandId=${errand.id}`,
            axiosInstance:Protected(sessionStorage.getItem('token'))
        })
    }
    useEffect(()=>response?window.location.reload():'',[response])
    return (
        <>       {!error&& <div className='errand'>
            <div className="errand_top">
                <div className="avatar">
                  <img src={user.value.avatar} alt="" />
                </div>
                <div className="names">
                   <h3>{user.value.fullName}</h3>
                   <p>@{user.value.username} <span>.  {displayTime()}</span></p>
                </div>
                <div onClick={()=>setOption(true)} className="svg">
                <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.25" cy="6.5" r="1.75" fill="#AEAEB9"/>
                    <circle cx="12.25" cy="13" r="1.75" fill="#AEAEB9"/>
                    <circle cx="12.25" cy="19.5" r="1.75" fill="#AEAEB9"/>
                </svg>

                </div>
            </div>
            <p className="message">
            {errand.errandNote}
            </p>
            <div className="errand_bottom">
                <p>Offer:N{errand.innitialOffer}</p>
                <div className="open">
                    Open
                </div>
            </div>
            { option &&<div className="options">
              <div>
                <p onClick={()=>{
                    navigate(`${errand.id}/edit`)
                    dispatch(loadErrand(errand))
            }}>Edit errand</p>
                <p>Close errand</p>
                <p onClick={()=>del()}>Delete errand</p>
                <p>View negotiation</p>
                
                </div>
                <Cancel onClick={()=>setOption(false)}/>
            </div>}
        </div>}
        {
            error&&
            <div className="alert">
<article>
   <div className="heading">
    <div  >
   <Cancel  onClick={()=>navigate(-1)}/>
</div>
       <h4 >
         Error
       </h4>
  
</div> 
<div className="message">
{error.message}
</div>
<span className="checkin" onClick={()=>navigate('/profile/errands')}>
  view errands
</span>
</article>
</div>
        }
        </>

    );
} 

export default ErrandCard;