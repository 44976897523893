import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom'
import {configureStore} from '@reduxjs/toolkit'
import { Provider } from 'react-redux';
import userReducer from './utils/features/user'
import errandsReducer from './utils/features/errands'
import errandReducer from './utils/features/errand'
import MessageReducer from './utils/messages/message'
import BidReducer from './utils/messages/bid'
import Mapping from './utils/features/mapCenter'

const store = configureStore({
  reducer:{
    user:userReducer,
    errand:errandsReducer,
    singleErrand:errandReducer,
    message:MessageReducer,
    bid:BidReducer,
    map:Mapping
  }
})
ReactDOM.render(
  <Provider store={store}>
  <BrowserRouter> 
    <App />
  </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
