import LeftArrow from "../../assets/svg/LeftArrow";
import ResetImg from '../../assets/png/resetImg.png'
import Button from "../../components/Button";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import hands from '../../assets/png/hands.png'
import { useEffect, useState } from "react";
import useAxios from "../../config/useAxios";
import axiosInstance from "../../config/axiosInstance";
import { ToastContainer, toast } from "react-toast";
import email from '../../assets/png/email.png'

export default function Reset(){

  const width= window.innerWidth
  return width<600?(
    <div class='auth reset'>
    <Routes>
        <Route path='' element={<ResetLink/>}/>
        <Route path='otp' element={< Verify />} />
        <Route path='newPassword' element={<EnterPassword/>}/>
    </Routes>

 </div>
   ):(
       <div className="big_auth">
       <div class='auth reset'>
           <Routes>
            <Route path='' element={<ResetLink />} />
            <Route path='otp' element={< Verify/>} />

               <Route path='new_password' element={<EnterPassword/>}/>
           </Routes>

        </div>
       <div className="image">
           <img src={hands} alt="" />
       </div>
       </div>
   )
   
}

function ResetLink(){
  const [response, error, fetchData, loading] = useAxios()
  const [mail,setMail]=useState()
  const resendOtp = (e) => {
      e.preventDefault()
      fetchData({
        method: 'GET',
        url: `user/auth/re-generate-otp?email=${mail}`,
        axiosInstance: axiosInstance
      })
    
   
  }
 const navigate=useNavigate()
  useEffect(()=>{
    if(error)toast.error(error.message)
    if(response) {
      sessionStorage.setItem('registerMail', mail)
      sessionStorage.setItem('sentTime', new Date())

      navigate('/reset/otp')
    }
  },[error,response])
    return(
        <>
        <ToastContainer position="top-center" delay={'5000'} />

        <div className="top">
        <LeftArrow onClick={()=>navigate(-1)} />
         </div>
        <div className="instruction">
        <h3>
          Reset password
        </h3>
        <p>
          Enter your email address and a link to reset your password will be sent to your mail
        </p>
       </div>
       <div className="img">
         <img src={ResetImg} alt="" />
        </div>
        <form action="">
        <input type="email" placeholder="Enter Email address" onChange={(e)=>setMail(e.target.value)} />
        <Button disabled={!mail} onClick={resendOtp} content='Send reset link' />
         </form>
        <span class='link'>
          Don't have an account? <Link to='/auth'>Register</Link>
        </span>
       </>
    )
}
function EnterPassword(){
    const [data,setData]=useState({})
    const [response, error, fetchData, loading] = useAxios()
const  submit =(e)=>{
   e.preventDefault()
      const {password,rePassword}= data

      if(password===rePassword && password){
        fetchData({
          method: 'post',
          url: `user/auth/reset-password`,
          axiosInstance: axiosInstance,
          requestConfig: {
            otp: sessionStorage.getItem('otp'),
            ...data
          } 
        })
      }
      else toast.error('two entries not equal')
    }

const navigate=useNavigate()
  useEffect(() => {
    if (error) toast.error(error.message)
    if (response) {
     
      navigate('/login')
    }
  }, [error, response])
    return(
     <>
        <ToastContainer position="top-center" delay={'5000'} />

         <div className="top">
          <LeftArrow onClick={() => navigate(-1)} />
         </div>
         <div className="instruction">
           <h3>
              New password
            </h3>
           <p>
              Enter new passowrd
           </p>
         </div>
         <div className="img">
            <img src={ResetImg} alt="" />
         </div>
         <form action="">
            <input onChange={(e)=>setData({...data,[e.target.name]:e.target.value})} name="password" type="password" placeholder="Enter password" />
          <input onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="rePassword" type="password" placeholder="Confirm Password" />
            <Button onClick={submit} content='Reset password' />
         </form>
         <span class='link'>
             Don't have an account? <Link to='/auth'>Register</Link>
         </span>
      </>
    )
}


 function Verify() {

  const [userInput, setUserInput,] = useState(new Array(5).fill(''))

  const mail = sessionStorage.getItem('registerMail')

  const [otpEntered, setOtp] = useState(false)
  const [response, error, fetchData, loading] = useAxios()
  // useEffect(() => {
  //   if (response && otpEntered) {
  //     navigate('/newPassword')
  //   }
  // }, [response])

  


  const resendOtp = () => {
    if (countDown == 0) {
      fetchData({
        method: 'GET',
        url: `user/auth/re-generate-otp?email=${mail}`,
        axiosInstance: axiosInstance
      })
    }
    else toast.success("Please wait , OTP is on the way")
  }
  const navigate = useNavigate()

  const countDownDate = new Date(sessionStorage.getItem('sentTime')).getTime() + (300 * 1000);
  // console.log(countDownDate);
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (new Date().getTime() < countDownDate) setCountDown(countDownDate - (new Date().getTime()));
      else setCountDown(0)
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);
  // console.log( new Date().getTime() , countDownDate);
  // return getReturnValues(countDown);
  const width = window.innerWidth
  return width < 600 ? (
    <>
      <ToastContainer position="top-center" delay={'5000'} />
      <div className="auth verify">
        <div className="top">
          <LeftArrow onClick={() => navigate(-1)} />
        </div>
        <div className="instruction">
          <h3>
            Verify email
          </h3>
          <p>
            An email with a verification code has been sent to <span className="mail">{mail}</span>.  Please enter the code to verify your account.
          </p>
        </div>
        {response && <span>{response.message}</span>}
        {error && <span style={{ color: 'red', textAlign: 'center' }}>{error.message}</span>}

        <div class='img'><img src={email} alt="" /></div>
        <form action="">

          {userInput.map((input, idx) => (
            // @ts-ignore
            <input key={idx} maxLength={1} value={userInput[idx]} style={{ textAlign: 'center' }} onPaste={async (e) => {
              const board = await navigator.clipboard.readText()
              if (board.length === 5) setUserInput([...board.split('')])
            }
            } onChange={(e) => {
              // @ts-ignore
              if (e.target.value && e.target.nextSibling) e.target.nextSibling.focus()

              setUserInput([...userInput.map((t, id) => id === idx ? e.target.value : t)])
            }} type='number' />
          ))}
        </form>

        <div className="link">Didnt recieve code? you can click <Link to='' onClick={resendOtp}>Resend</Link>  {countDown > 0 && `in ${getReturnValues(countDown)[0]}:${getReturnValues(countDown)[1]}`}</div>
        <Button disabled={loading ? true : false} content={loading ? 'Please wait' : 'Verify email'} onClick={(e) => {
          e.preventDefault()
          sessionStorage.setItem("otp",userInput.join(''))
          navigate('/reset/newPassword')
        }} />
      </div>
    </>

  ) : (
    <>
      <ToastContainer position="top-center" delay={'5000'} />
      <div className="big_auth">
        <div className="auth verify">
          <div className="top">
            <LeftArrow onClick={() => navigate(-1)} />
          </div>
          <div className="instruction">
            <h3>
              Verify email
            </h3>
            <p>
              An email with a verification code has been sent to <span className="mail">{mail}</span>.  Please enter the code to verify your account.
            </p>
          </div>
          {response && <span>{response.message}</span>}
          {error && <span style={{ color: 'red', textAlign: 'center' }}>{error.message}</span>}

          <div class='img'><img src={email} alt="" /></div>
          <form action="">
            {userInput.map((input, idx) => (
              // @ts-ignore
              <input maxLength={1} value={userInput[idx]} style={{ textAlign: 'center' }} onPaste={async (e) => {
                const board = await navigator.clipboard.readText()
                if (board.length === 5) setUserInput([...board.split('')])
              }
              } onChange={(e) => {
                // @ts-ignore
                if (e.target.value && e.target.nextSibling) e.target.nextSibling.focus()

                setUserInput([...userInput.map((t, id) => id === idx ? e.target.value : t)])
              }} type='number' />
            ))}
          </form>
          <div className="link">Didnt recieve code? you can click<Link to='' onClick={resendOtp}>Resend</Link> {countDown > 0 && `in ${getReturnValues(countDown)[0]}:${getReturnValues(countDown)[1]}`}</div>
          <Button disabled={loading ? true : false} content={loading ? 'Please wait' : 'Verify email'} onClick={(e) => {
            e.preventDefault()
          
              sessionStorage.setItem("otp", userInput.join(''))
              navigate('/reset/newPassword')
          }} />
        </div>
        <div className="image">
          <img src={hands} alt="" />
        </div>
      </div>
    </>

  )

}

const getReturnValues = (countDown) => {
  // calculate time left
  // const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  // const hours = Math.floor(
  //     (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  // );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [minutes, seconds];
};