import { useEffect, useState } from "react"

const useAxios=()=>{
  
   const [response,setResponse]=useState()
   const [loading,setLoading]=useState(false)
   const [error,setError]=useState('')
//    const [redirect,setRedirect]=useState(false)
    // const [controller,setController]=useState()
    const ctrl=new AbortController()

   const fetchData= async(configObj)=>{
       
    const {
        axiosInstance,
        method,
        url,
        requestConfig={},
        callback

    }=configObj
    
    
    setLoading(true)
       try {
           const {data,status}= await axiosInstance[method.toLowerCase()](url,{
               ...requestConfig,
            //    signal:ctrl.signal
           })
           setResponse(data)
        //    console.log(data);
           callback()
           
           
           
       } catch (error) {
           setError(error?.response?.data)
        //    console.log(error)
           
       }
       finally{
           setLoading(false)
       }
   }
   useEffect(()=> ctrl.abort(),[])
   
    return[response,error,fetchData,loading]
}

export default useAxios;