

 const GetTime=()=>{    
     
     const getDiff=(data)=>{
   
        let time= new Date(data) 
        let currentTime= new Date()
        let diff=currentTime-time
        const updateTime={
            seconds:diff/1000,
            minutes:(diff/1000)/60,
            hours:(diff/1000)/3600,
            days:((diff/1000)/3600)/24
          }
        const {hours,days,seconds,minutes}=updateTime
       
     
         if(Math.floor(days)>0){
             return `${Math.floor(days)}d`
         }
         else if(Math.floor(hours)>0){
             return `${Math.floor(hours)}h`
         }
         else if(Math.floor(minutes)>0){
             return `${Math.floor(minutes)}m`
         }
         else return `${Math.floor(seconds)}s`

        }
return{   getDiff} 
}
export default GetTime;