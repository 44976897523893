import React, { useState } from "react";
import _protected from '../../config/protected'
// import wallet from "../../assets/png/wallet.png";
import { ToastContainer, toast } from "react-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAxios from "../../config/useAxios";
import { useEffect } from "react";
import user from "../../utils/features/user";
import GetTime from "../../hooks/gettingTIme";
import { Bars } from "react-loading-icons";

function Wallet(props) {
    
  const user=useSelector(state=>state.user)
  const navigate= useNavigate()
   const [balance,error,fetchBalance,loadBalance]=useAxios()
   const [transactions,transactionerror,fetchTransactions,loadTransaction]=useAxios()
    const [bal,setBal]=useState([])
  useEffect(()=>{
    fetchBalance({
        method:'get',
        url:'user/balance',
        axiosInstance:_protected(sessionStorage.getItem('token'))

    })
    fetchTransactions({
        method:'get',
        url:'transactions',
        axiosInstance:_protected(sessionStorage.getItem('token'))

    })
  },[])

  useEffect(()=>{if(balance?.length)setBal(balance)},[balance])
 return loadBalance?(<p>Please wait....</p>): (
    <>
     <ToastContainer position="top-center" delay={2000}/>
         <div className="wallet">
     
      <div className="balances">
      <div className="instruction">
        <h3>My Wallet</h3>
        {/* <span >Fund wallet</span> */}
      </div>
       
        <div className="balance">
          {/* <span>My balance</span> */}
         <> 
              <h3>N {bal[0]?.balance?.toLocaleString('en-US')}</h3>
              <p>Withdrawal:</p>
             <h3>N {bal[1]?.balance?.toLocaleString('en-US')}</h3></>
        {loadBalance && <Bars height={'40px'} width={'60px'}/>}
        </div>
        <div className="actions">
          <div className="fund" onClick={()=>navigate('/profile/fund-wallet')}>
              Fund Wallet
          </div>
          <div className="withdraw" onClick={()=>navigate('/profile/withdraw')}>
             Withdraw
          </div>
        </div>
      </div>
      <div className="weekly_stats">
        {/* <div className="instruction">
          <p>Weekly stats</p>
          <p>
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.62012 4.45337L13.6668 8.50004L9.62012 12.5467"
                stroke="#AEAEB9"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.33301 8.5H13.553"
                stroke="#AEAEB9"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </p>
        </div>
        <div className="stats">
          <div style={{ background: "#FAF8EA" }}>
            <div>
              <p>money recieved</p>
              <svg
                width="22"
                height="13"
                viewBox="0 0 22 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.9999 1L11.6666 10.3333L8.11106 4.99997L1 12.111"
                  stroke="#35CA21"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5557 1H21.0001V5.44441"
                  stroke="#35CA21"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <h3>20,000 NGN</h3>
          </div>
          <div style={{ background: "#EDEAFA" }}>
            <div>
              <p>payment made</p>
              <svg
                width="22"
                height="13"
                viewBox="0 0 22 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 12.1111L11.6667 2.77778L8.11111 8.11111L1 1"
                  stroke="#DF4C4C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5557 12.1112H21.0001V7.66675"
                  stroke="#DF4C4C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <h3>20,000 NGN</h3>
          </div>
        </div> */}
        <div className="instruction">
          <h3 style={{color:'black'}}>Recent Transactions</h3>
        </div>
        <div className="transactions">
          {transactions?.results.map((tran) => (
            <Trans key={tran?.referenceId} {...tran} />
          ))}
        </div>
      </div>
    </div>
   </>
 
  );
}

export default Wallet;

const Trans = ({sender,createdAt, receiver,amount,receiverData,senderData,transactionType }) => {
    const user=useSelector(state=>state.user);
    const {getDiff}=GetTime()
  return (
    <div
      style={{ background: `${transactionType=='CASH OUT'?'#FEFBFB':user?.value?.id==receiver  ? "#FBFEFC" : "#FEFBFB"}` }}
      className="trans"
    >
      <div className="img">
        <img src="" alt="" />
      </div>
      <div className="trans_detail">
        <div className="name_amount">
          {receiver==user?.value?.id&&receiver!==sender && (
            <p>
              <span>@{senderData.username}</span> paid you
            </p>
          )}
          {receiver!=user?.value?.id && (
            <p>
              you paid <span>@{receiverData?.username}</span>
            </p>
          )}
          {receiver==sender&&transactionType!='CASH OUT' && (
            <p>
              you funded <span>your wallet </span> with
            </p>
          )}
          {receiver==sender &&transactionType=='CASH OUT' && (
            <p>
              you withdrew
            </p>
          )}
          <h3>N{amount}</h3>
        </div>
        <div className="svg_time">
          {receiver==user?.value?.id && (
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99967 1.83325C4.31967 1.83325 1.33301 4.81992 1.33301 8.49992C1.33301 12.1799 4.31967 15.1666 7.99967 15.1666C11.6797 15.1666 14.6663 12.1799 14.6663 8.49992"
                stroke="#35CA21"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.6669 1.83325L9.2002 7.29992"
                stroke="#35CA21"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.66699 4.61328V7.83328H11.887"
                stroke="#35CA21"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
         { receiver!=user?.value?.id &&
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99967 1.83325C4.31967 1.83325 1.33301 4.81992 1.33301 8.49992C1.33301 12.1799 4.31967 15.1666 7.99967 15.1666C11.6797 15.1666 14.6663 12.1799 14.6663 8.49992"
              stroke="#DF4C4C"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.66699 7.83337L14.1337 2.3667"
              stroke="#DF4C4C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.6663 5.05325V1.83325H11.4463"
              stroke="#DF4C4C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>}
          <p style={{color:'#AEAEB9'}}>{getDiff(createdAt)} ago</p>
        </div>
      </div>
    </div>
  );
};
