import { useEffect, useRef, useState } from "react"
import ClosedEye from "../../assets/svg/closedEye"
import OpenEye from "../../assets/svg/openEye"
import Button from "../../components/Button"
import LeftArrow from '../../assets/svg/LeftArrow'
import { Link, useNavigate } from "react-router-dom"
import AuthHook from "../../hooks/auth"
import hands from '../../assets/png/hands.png'
import { toast, ToastContainer } from "react-toast"

export default function AuthPage(){
    const [showPassword,setShowPassword]=useState(false)
    const [signUpPArarams,setSignUpParams]=useState({username:'',password:'',phoneNumber:'',email:'',fullName:''})
    const navigate=useNavigate()
    const {register,loading,response,error}=AuthHook()
   
    useEffect(()=>{
        if(response===200){
            sessionStorage.setItem('sentTime',new Date())
            navigate('/verify/'+signUpPArarams.email)
        }
    },[response])
    const submit=()=>{
    
     const  {username,password,phoneNumber,email,fullName}=signUpPArarams;
     if(username&&password&&phoneNumber&&email&&fullName){
        
        sessionStorage.setItem('registerMail',email)
         register(signUpPArarams)
     }else {
         toast.error('Fill all')
     };
    }
   useEffect(()=>{
    if(error){
        if (error?.message) toast.error(error.message)
       else error.map(err => toast.error(`${err.value}, ${err.msg}`))
    }
}
   ,[error])

    // console.log(error);
    const width= window.innerWidth
   return <>
   <ToastContainer position="top-center" delay={'1000'}/>
  { width<600?(
        <div class='auth sign_up'>
   
            <div className="top">
                <LeftArrow onClick={()=>navigate(-1)} />
            </div>
           <div className="instruction">
                <h3>
                  Get right into it
                 </h3>
                <p>
                  Supply these details let’s get you started, asap!.
                </p>

            </div>
            <form onSubmit={(e)=>{
                e.preventDefault()
                submit()
            }}>
                {error && <span style={{margin:'auto',display:'block',textAlign:'center',fontSize:'12px',color:'red'}}>{error.message}</span>}
                <input type="text" placeholder="Email address" name='email' onChange={(e)=>setSignUpParams({...signUpPArarams,[e.target.name]:e.target.value})}/>

                <input type="text" placeholder="Full name" name='fullName' onChange={(e)=>setSignUpParams({...signUpPArarams,[e.target.name]:e.target.value})} />
                
                <input type="text" placeholder="Username" name='username' onChange={(e)=>setSignUpParams({...signUpPArarams,[e.target.name]:e.target.value})}/>
                <input type="text" placeholder="Phone number" name='phoneNumber' onChange={(e)=>setSignUpParams({...signUpPArarams,[e.target.name]:e.target.value})}/>
               
             
               <div className="carries_svg">
                   <input type={showPassword ? 'text' : 'password'} placeholder="Password,must contain a symbol, and a number" name='password' onChange={(e)=>setSignUpParams({...signUpPArarams,[e.target.name]:e.target.value})}/ > 
                    <div className="svg">
                     {!showPassword && <span onClick={()=>setShowPassword(!showPassword)}><OpenEye /></span>}
                     {showPassword && <span onClick={()=>setShowPassword(!showPassword)}><ClosedEye/></span>}
                    </div>
              </div>
                   <input type="text" placeholder="Reference ID" name='reference' onChange={(e) => setSignUpParams({ ...signUpPArarams, [e.target.name]: e.target.value })} />

               <Button onClick={(e)=>{
                   e.preventDefault()
                   submit()
               }}
               disabled={loading?true:false}
               content={loading?'Please wait':'Register'} />
            </form>

            <span class='link'>
            Already have an account? <Link to='/login'>Login</Link>
            </span>
        </div>
    ):(
        <div className="big_auth">
        <div class='auth sign_up'>


<div className="instruction">
    <h3>
      Get right into it
     </h3>
    <p>
      Supply these details let’s get you started, asap!.
    </p>

</div>
<form onSubmit={(e)=>{
    e.preventDefault()
    submit()
}}>
    {error && <span style={{margin:'auto',display:'block',textAlign:'center',fontSize:'12px',color:'red'}}>{error.message}</span>}
    <input type="text" placeholder="Email address" name='email' onChange={(e)=>setSignUpParams({...signUpPArarams,[e.target.name]:e.target.value})}/>

    <input type="text" placeholder="Full name" name='fullName' onChange={(e)=>setSignUpParams({...signUpPArarams,[e.target.name]:e.target.value})} />
    
    <input type="text" placeholder="Username" name='username' onChange={(e)=>setSignUpParams({...signUpPArarams,[e.target.name]:e.target.value})}/>
    <input type="text" placeholder="Phone number" name='phoneNumber' onChange={(e)=>setSignUpParams({...signUpPArarams,[e.target.name]:e.target.value})}/>
    
    
   <div className="carries_svg">
        <input type={showPassword?'text':'password'} placeholder="Password,must contain a symbol, a capital letter and a number" name='password' onChange={(e)=>setSignUpParams({...signUpPArarams,[e.target.name]:e.target.value})}/ > 
        <div className="svg">
         {!showPassword && <span onClick={()=>setShowPassword(!showPassword)}><OpenEye /></span>}
         {showPassword && <span onClick={()=>setShowPassword(!showPassword)}><ClosedEye/></span>}
        </div>
  </div>
  <input type="text" placeholder="Reference ID" name='reference' onChange={(e) => setSignUpParams({ ...signUpPArarams, [e.target.name]: e.target.value })} />

   <Button onClick={(e)=>{
       e.preventDefault()
       submit()
   }}
   disabled={loading?true:false}
   content={loading?'Please wait':'Register'} />
</form>

<span class='link'>
Already have an account? <Link to='/login'>Login</Link>
</span>
        </div>
        <div className="image">
            <img src={hands} alt="" />
        </div>
        </div>
    )}</>
}