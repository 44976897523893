import { createSlice } from "@reduxjs/toolkit";

export const BidSlice= createSlice({
    name:'bidding',

    initialState:{newBid:false,
    
     activeBids:{type:'pending',bids:[],loading:false},
}
    ,
     reducers:{
        setNewBid:(state,action)=>{
          state.newBid=action.payload  
        },
        setBidType:(state,action)=>{
         state.activeBids.type=action.payload
        },
        setOtherPartyBid:(state,action)=>{
           state.otherPartyChats=action.payload
        },
        setActiveBids:(state,action)=>{
            state.activeBids.bids=action.payload
        },
        setBidLoading:(state,action)=>{
            state.activeBids.loading=action.payload
            // console.log(action.payload);
        }
     }
})

export const {setOtherPartyBid,setNewBid,setBidType,setActiveBids,setBidLoading}=BidSlice.actions;
export default BidSlice.reducer;