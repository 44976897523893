import React, { useState } from 'react';
import { useEffect } from 'react';
import Cancel from '../../assets/svg/Cancel';
import Button from '../../components/Button';
import Protected from '../../config/protected';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../config/useAxios';
import { ToastContainer, toast } from 'react-toast';

function Withdraw(props) {

    const [amount,setAmount]=useState()
    const [banks,error,getBanks,loading]=useAxios()
  const [verifyAcc, vError, getDet, loadDet] = useAxios()
  const [Acc, accError, getAcc, loadAc] = useAxios()
  const [updatedc, upAccError, updateAcc, updating] = useAxios()
    const [withdrawData,werror,withdraw,loadingwithdrawal]=useAxios()
    const [userDetails,setUserDetails]=useState({})
    const navigate= useNavigate()
    useEffect(()=>{
      getBanks({
        method: 'get',
        url: 'transactions/paystack/list-banks',
        axiosInstance: Protected(sessionStorage.getItem('token'))
      });
      getAcc({
        method: 'get',
        url: 'user/bank-account',
        axiosInstance: Protected(sessionStorage.getItem('token'))
      })
    },[])
    
    useEffect(()=>{
        const {bankCode,accountNumber}=userDetails
      
        if(bankCode && accountNumber?.length==10  )
            getDet({
               method:'get',
              url:`/transactions/paystack/verify-account?bankCode=${bankCode}&accountNumber=${accountNumber}`,
               axiosInstance:Protected(sessionStorage.getItem('token'))
       })
    },[userDetails?.accountNumber])
    
     useEffect(()=>{
    
       setUserDetails(Acc??{...userDetails,name:verifyAcc?.data?.account_name})
      if(Acc) setBankname(banks?.data.filter(bank=>bank?.code===Acc.bankCode)[0]?.name);
     },[verifyAcc,Acc])

   

   const [showPassword, setShowPassword] = useState(false)
   const [showPrompt, setShowPrompt] = useState(false)
    const [password,setPassword]=useState()
    const [confirmResponse, confirmError, confirmData, confirmLoading] =
    useAxios();
    const EnterPassword=()=>{
        confirmData({
            method: "POST",
            url: "user/verify-by-password",
            axiosInstance: Protected(sessionStorage.getItem("token")),
            requestConfig: {
              password: password,
            },
          });
    }
    const widthdraw=(e)=>{
        e.preventDefault()
     
      const {bankCode,accountNumber}=userDetails
      if(bankCode&& accountNumber){
     
      if (accError ){
         setShowPrompt(true)
       }
       else setShowPassword(true)
      }
    }

    useEffect(()=>{
        if(confirmResponse) withdraw({
            method:'post',
            url:'transactions/withdrawal',
            axiosInstance:Protected(sessionStorage.getItem('token')),
            requestConfig:{
               ...userDetails
            }
        })
    },[confirmResponse])


    useEffect(()=>{
        if(withdrawData) navigate(-1)
    },[withdrawData])
  
    useEffect(()=>{
       if(werror)toast.error('An error Occured')
    },[werror])

const updateAccount=()=>{
  updateAcc({
    method: "POST",
    url: "user/bank-account",
    axiosInstance: Protected(sessionStorage.getItem("token")),
    requestConfig: {
      "accountNumber": userDetails.accountNumber,
      "name": userDetails.name,
      "bankCode": userDetails.bankCode,
      "currency": "NGN"
    }
  })
  
}
  useEffect(() => { if (updatedc) setShowPassword(true);
                    if(upAccError) toast.error("Try again later")
   }, [updatedc,upAccError])

    const [bankName,setBankname]=useState('')
    const [bank,setBank]=useState([])
    useEffect(()=>{if(banks)setBank(banks.data)},[banks])
    const sort=(e)=>{
      
      if(e.trim())setBank(banks?.data.filter(bnk=>{  return bnk.name.toLowerCase().includes(e)}))
      else setBank(banks?.data)

    }
    
    const [showBanks,setShowBanks]=useState(false)
    return (
        <>
        <ToastContainer position="top-center" delay={'10000'} />
          {
          showPrompt && (
            <div className="alert">
              <article>
                {confirmError && <p style={{ color: 'red', fontSize: '10px' }}>{confirmError.message}</p>}
                <div className="heading"><div>
                  <Cancel onClick={() => setShowPrompt(false)} />
                </div>
                  <h4>Confirm Account</h4>

                </div>
                <div className="message">
                  You cannot change your withdrawal account after this, and will have to contact us if you want to.
                </div>
                <span style={{ marginTop: '20px' }} className="checkin" onClick={() => updateAccount()}>
                  {loading || confirmLoading || loadingwithdrawal ? "Please Wait" : "Continue"}
                </span>
              </article>
            </div>
          )
          }
         {showPassword && (
            <div className="alert">
              <article>
                {confirmError && <p style={{color:'red',fontSize:'10px'}}>{confirmError.message}</p>}
                <div className="heading"><div>
                    <Cancel onClick={() => setShowPassword(false)} />
                  </div>
                  <h4>Enter Password to continue</h4>
                  
                </div>
                <div className="message">
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    type={"password"}
                  />
                </div>
                <span style={{marginTop:'20px'}} className="checkin" onClick={()=>EnterPassword()}>
                 {loading||confirmLoading||loadingwithdrawal?"Please Wait":"Continue"}
                </span>
              </article>
            </div>
          )}
        <div className='auth withdrawal'>
              <div className="instruction">
        <h3>Withdrawal</h3>
      </div>
      <form action="">
        {werror && <p style={{color:'red',fontSize:'10px'}}>{werror.message}</p>}
                {/* {error && <span style={{margin:'auto',display:'block',textAlign:'center',fontSize:'12px',color:'red'}}>{error}</span>} */}
                <input type="number" placeholder="Enter Amount"name='amount' onChange={(e)=>setUserDetails({...userDetails,[e.target.name]:e.target.value})} />
                <div style={{position:'relative',width:'100%'}}>
                    
              <input  value={bankName?bankName:null}  placeholder='Select bank' onClick={()=>setShowBanks(!showBanks)}  onChange={(e)=>sort(e.target.value)} type="text" />
              {showBanks&& <div style={{position:'absolute',zIndex:'99',maxHeight:'200px',overflowY:'scroll',background:'white'}}>
               
                          { bank.map(bank=>(
                             <div onClick={() =>{
                             
                              setUserDetails({ ...userDetails, bankCode:bank.code }); setBankname(bank.name);setShowBanks(false)}} key={bank.id} >{bank.name}</div>
                        ))
                    }
              </div>}
                   
                </div>
               
            <input type="number" value={userDetails?.accountNumber}  placeholder="Enter Account Number" name='accountNumber' onChange={(e)=>setUserDetails({...userDetails,[e.target.name]:e.target.value})} />
                <input type="text" onChange={(e)=>setUserDetails({...userDetails,[e.target.name]:e.target.value})}   placeholder="Account Name" name='name' value={verifyAcc?.data.account_name} readOnly />
                <textarea placeholder='description'></textarea>
               {/* <div className="carries_svg"> 
                     <input type={showPassword?'text':'password'} placeholder='Password' name='password'/>
                     <div className="svg">
                     {!showPassword && <span onClick={()=>setShowPassword(!showPassword)}><OpenEye /></span>}
                     {showPassword && <span onClick={()=>setShowPassword(!showPassword)}><ClosedEye/></span>}
                    </div>
               </div> */}
                {/* <Link to={'/reset'} className="forgot"><span>forgot passowrd?</span></Link> */}
                <Button onClick={widthdraw} content={'Continue'} />
            </form>
        </div>
        </>
    );
}

export default Withdraw;


