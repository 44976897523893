import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Protected from "../../config/protected";
import useAxios from "../../config/useAxios";
import { setActiveBids, setNewBid,setBidLoading } from "./bid";
import { setActiveChats, setChatsLoading, setOtherPartyChats, setUnseen } from "./message";

const Utility=()=>{
    const [users, userError, fetchUsers, userLoading] = useAxios();
   
    const [otherParty, setOtherParty] = useState();
    const message = useSelector((state) => state.message);
    const dispatch= useDispatch()
  
      const [unseenArray, setUnseenArray] = useState([]);
      const [arranged, setArranged] = useState([]);

    


     

      useEffect(() => dispatch(setChatsLoading(userLoading)), [userLoading]);


      useEffect(() => {
     
       if(users) {
        dispatch(setActiveChats(users?.results));
        dispatch(setUnseen(users?.unseenMessages?.length))}
      },[users]);


      // texting
      const [chats, chatError, getChats, chatLoading] = useAxios();
      
      const getChatDefault = () => {
        getChats({
          method: "get",
          url: `chat/user/${otherParty || message.otherPartyID}`,
          axiosInstance: Protected(sessionStorage.getItem("token")),
        });
      };
    
      useEffect(() => {
        if (message.otherPartyID) {
          getChatDefault();
        }
      }, [message.otherPartyID]);


      useMemo(() => {
        if (chats) dispatch(setOtherPartyChats(chats?.results?.reverse()));
      }, [chats]);
    
      // bidding
      const [bidresponse, biderror, fetchBids, loadBids] = useAxios();
      const biddings = useSelector((state) => state.bid);

      const getBid = (data) => {
        dispatch(setBidLoading(true))
        dispatch(setActiveBids([]));
        fetchBids({
          method: "GET",
          url: `errands/negotiation/${data}`,
          axiosInstance: Protected(sessionStorage.getItem("token")),
        });
      };
      const apiKey = "AIzaSyApvdZmqFeDqpjXDwdmegyobL_aCip2GTE";

    
    //   this gets the bid
      useEffect(() => getBid(biddings.activeBids.type), [biddings.activeBids.type]);
      useEffect(() => {
        dispatch(setActiveBids(bidresponse));
        dispatch(setBidLoading(false))
      }, [bidresponse]);
      
     const getCuurentMessageData=()=>{

        fetchUsers({
            method: "get",
            url: "chat/all-users",
            axiosInstance: Protected(sessionStorage.getItem("token")),
          });
          
      }
    
      
    
     
    
    
      useEffect(() => {
       
         if(biddings.newBid==true){
          getBid(biddings.activeBids.type);
          dispatch(setNewBid(false))}
        }
      , [biddings.newBid]);

      return{
        getBid,
        getCuurentMessageData,
    
       }
}

export default Utility