import React, { useCallback, useState } from "react";
import LeftArrow from "../../assets/svg/LeftArrow";
import Search from "../../assets/svg/Search";
import Button from "../../components/Button";
import usePlacesAutocomplete,{getGeocode, getLatLng} from "use-places-autocomplete";
// import { Combobox } from "@reach/combobox";
import '@reach/combobox/styles.css'
import { GoogleMap, useJsApiLoader,useLoadScript, Marker } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import ThreeDots from "react-loading-icons/dist/esm/components/three-dots";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFrom, setTo } from "../../utils/features/mapCenter";
import { useNavigate } from "react-router-dom";


  function LoadLocation(){
    const key= process.env.REACT_APP_GOOGLE
  
    const { isLoaded } = useLoadScript({
      googleMapsApiKey:key,
      libraries: ["places"],
    });
    if(!isLoaded){
      return(<div>Loading...</div>)
    }
    return(
      <Location isLoaded={isLoaded}/>
    )
  }
function Location(props) {

  const [location, setLocation] = useState();
  const dispatch=useDispatch()
  const center = useSelector((state) => state.map);
  const navigate=useNavigate()
  const {ready,value,setValue,suggestions:{status,data},clearSuggestions}=usePlacesAutocomplete()
  const [places,setPlaces]=useState([])
  useEffect(()=>{
    if(status=='OK'){
      setPlaces(data)
    }
  },[value,data,ready])
 
  const select=(data)=>{
    if(location=='you')dispatch(setTo(data))
    else dispatch(setFrom(data))
  }

  
  const [yourLocation, setYourLocation] = useState(false)
  const [errLocation, setErrLocation] = useState(false)
   return (
    <>
      <div className="top">
         <LeftArrow onClick={() => navigate(-1)} />
      </div>
      <div className="instruction">
        <div class="instruction_top">
          <h3>Select location</h3>
        </div>
      </div>

      <form action="">
        <div className="carries_svg">
        
          {!yourLocation&&<input
           onClick={()=>{
            setYourLocation(true)
            
          }}
            type="text"
            value={center.yourLocation.description?center.yourLocation.description:null}
            readOnly= {false}
              onBlur={(e)=>{
               setYourLocation(true)
              }}
             placeholder={ "Delivery Location"} 
          />}
          {
             yourLocation && <input
              
               type="text"
               
               readOnly={false}
               onFocus={(e) => {
                 setLocation('you')
               }}
               onBlur={(e) => {
                 setYourLocation(false)
               }}
               placeholder={"Delivery Location"} onChange={(e) => setValue(e.target.value)}
             />
          }
          
       
          <div className="svg">
            <Search />
          </div>
        </div>
        <div className="carries_svg">
      
           {!errLocation && <input
             type="text"
             // value={value}
             value={center.errandLocation.description?center.errandLocation.description:null}
             onClick={() => {
               setErrLocation(true)
 
             }}
             

             onBlur={(e) => {
               e.target.value = center.errandLocation.description ? center.errandLocation.description : null
             }}

             placeholder={center.errandLocation.description ? center.errandLocation.description : "Errand Location"} onChange={(e) => setValue(e.target.value)}
           />}{errLocation && <input
             type="text"
             
             onFocus={(e) => {
               
               setLocation('errand')
             }}

             onBlur={(e) => {
              setErrLocation(false)
             }}

             placeholder={center.errandLocation.description ? center.errandLocation.description : "Errand Location"} onChange={(e) => setValue(e.target.value)}
           />}
      
       
          <div  className="svg">
            <Search />
          </div>
        </div>
        <Button onClick={(e)=>{
          e.preventDefault()
         
          clearSuggestions()
          navigate(-1)
        }} content="Done" />

        
          <div className="select_location">
            <LocationRack setVal={setValue} isLoaded={props.isLoaded} places={places} select={select} clearSuggestions={clearSuggestions}/>
          </div>
                
      
      </form>
    </>
  );
}

export default LoadLocation;

function LocationRack({ onClick, places,isLoaded,setVal,...props }) {
  const [map, setMap] = useState(false);
  const center = useSelector((state) => state.map);
  const [marks,setMarks]=useState([])
  const [pan,setPan]=useState(/**@type google.maps.Map */(null))
  const handleSelect= async (data)=>{
    setVal(data,false)
    props.clearSuggestions()
    
    const result= await getGeocode({address:data})
    const {lat,lng}= await getLatLng(result[0])
    props.select([lng,lat,data])
  }

 const getLat= async(data)=>{
  const result= await getGeocode({address:data})
  const {lat,lng}= await getLatLng(result[0])
  return [{lat,lng},data]
 }
 const getALL=async(array)=>{
  const locations= await Promise.all( array.map( place=> getLat(place.description)))
  setMarks(locations)
 }

useEffect(()=>{

  if(isLoaded && map) pan.panTo(marks[1][0])
},[places])
 useEffect(()=>{if(places)getALL(places)},[places])



if(!isLoaded){
  return(<ThreeDots/>)
}else
    return (
      <>
        <div className="instruction">
          <div class="instruction_top lower">
            
           {!map&& <span onClick={()=>{
              setMap(true)
            
            }}>Select from map</span>}
            {map && <span onClick={()=>
              setMap(false)}>Close map</span>}
          </div>
        </div>
        { places?.length>0 &&<div className="location_rack" onClick={onClick}>
         {places?.map(place=>( <div key={place.description} onClick={()=>handleSelect(place.description)} className="location">
           <h4>{place.description}</h4>
          </div>))}
        </div>}
        {
          map && <div className="map">
          <GoogleMap
            center={{
              lat: center?.lat,
              lng: center?.lng,
            }}
            zoom={10}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            options={{
              mapTypeControl: false,
            }}
            onLoad={(map) => setPan(map)}
          >
            <Marker
              position={{
                lat: center?.lat,
                lng: center?.lng,
              }}
            />
            {
              marks?.map(place=>
             
                (<Marker onClick={()=>props.select([place[0].lng,place[0].lat,place[1]])} position={place[0]}/>) 
                )
            }
            {
              center.yourLocation && <Marker label={'To'} position={center.yourLocation[0]}/>
            }
            {
              center.errandLocation && <Marker label={'From'} position={center.errandLocation[0]}/>
            }
             
          </GoogleMap>
        </div>
        }
      </>
    );
}
