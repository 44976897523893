import axios from 'axios'
import { useState } from 'react';
import {Base_url} from '../config/axiosInstance'




 const Update=()=>{
      const url=Base_url;
      const formData= new FormData()
      const[response,setResponse]=useState()
      const [loading,setLoading]=useState(false)
      const[error,setError]=useState('')
       const token= sessionStorage.getItem('token')
        
     

       const update=  async (parameter)=>{
    //  console.log(token);
          for(let key in parameter){
            formData.append(key,parameter[key])
          }
          setLoading(true)
        try{
         const {data,status}=await axios({
           method:'patch',
           url:`${url}user`,
           headers:{
            'content-type':'application/json',
            'Authorization':`Bearer ${token}`
        },
           data:formData
         })
        //  console.log(status,data.message)
          setResponse(data)
          
        }
        catch (error){
         setError(error.response.data.message);
        }
        finally{
          setLoading(false)
        }

      }

   
      
  
      return {loading,response,error,update}
    

}
export default Update;