import { createSlice } from "@reduxjs/toolkit";

export const MessageSlice= createSlice({
    name:'messaging',

    initialState:{otherPartyID:'',
     otherPartyChats:[],
     activeChats:{chats:[],loading:false},
    unseenCount:''}
    ,
     reducers:{
        setOtherParty:(state,action)=>{
          state.otherPartyID=action.payload  
        },
        setUnseen:(state,action)=>{
          state.unseenCount=action.payload
        },
        setOtherPartyChats:(state,action)=>{
           state.otherPartyChats=action.payload
        },
        setActiveChats:(state,action)=>{
            state.activeChats.chats=action.payload
        },
        setChatsLoading:(state,action)=>{
            state.activeChats.loading=action.payload
        }
     }
})

export const {setOtherParty,setOtherPartyChats,setActiveChats,setUnseen,setChatsLoading}= MessageSlice.actions;
export default MessageSlice.reducer;