import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import _protected from '../../config/protected';
import useAxios from '../../config/useAxios';
import Post from './Post';
import {Top} from '../Home/index'
import ErrandCard from './ErrandCard';
import Protected from '../../config/protected';
import Button from '../../components/Button';
import { ThreeDots } from 'react-loading-icons';
function Myerrands() {
  
    return (
        <div class='my_errand auth'>
            <Routes>
               <Route path='' element={<Index/>}/>
               <Route path=':errandId/edit' element={<Post head={'Edit errand'}
               button={'Confirm'}

               />}/>
            </Routes>   
        </div>
    );
}

export default Myerrands;

const Index=()=>{
    const [response,error,fetchData,loading]=useAxios()

    // #infinte scroll
    const [page, setPage] = useState(1)
    const [reload, setReload] = useState(false)
    
    const interDemo = useRef(null);

    const callBack = (entries) => {
        // console.log(entries);

        const [entry] = entries;
        // console.log(entry.isIntersecting);

        if (entry.isIntersecting ) {
         
            setReload(true)
        }

    };
    let options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
    };
 
    useEffect(() => {
        const observer = new IntersectionObserver(callBack, options);
        const target = interDemo.current;
        if (target) observer.observe(target);

        return () => {
            if (target) {
                observer.unobserve(target);
            }
        };
    }, [interDemo]);

    useEffect(()=>{
        if(reload && !loading)
        {  if(page<response?.totalPages)setPage(page+1)}   
        return ()=> setReload(false)   
    }       
        ,[reload])
    
        const [errands,setErrands]= useState([])
        useEffect(()=>{
            if(response) setErrands([...errands,...response?.errands])
        },[response])
        
    useEffect(()=>{
        fetchData({
            method:'GET',
            url:`/user/errands?limit=5&page=${page}`,
            axiosInstance:Protected(sessionStorage.getItem('token'))
        })

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        return ()=> setReload(false)

    },[page])
    const navigate =useNavigate()
    return(
        <>
           {/* <Top head='My'/> */}
                <div className="errands_rack">
                {errands?.map(errand=>(
                    <ErrandCard errand={errand}/>
                    ))}

                    {
                      !loading&&  !response?.errands.length && <Button content={"Create Errand"} onClick={()=>navigate('/get_help')}/>
                    }
                {loading && <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "48px",
                    }}
                >
                    <ThreeDots />
                </div>}
                </div>
            <div className="loadmore" ref={interDemo}></div>

        </>
    )
}