import React from 'react';

function Phone(props) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.1389 11.9827C14.5791 11.8623 14.1363 12.1222 13.7442 12.3491C13.3427 12.583 12.5791 13.2024 12.1415 13.0439C9.90074 12.1213 7.79328 10.16 6.88099 7.91033C6.7202 7.46333 7.33671 6.69495 7.56886 6.28867C7.79414 5.89538 8.04865 5.44838 7.93257 4.88443C7.82767 4.37766 6.47085 2.65118 5.99106 2.17906C5.67464 1.8672 5.35049 1.69568 5.01773 1.66795C3.76667 1.61425 2.36943 3.28356 2.12438 3.68291C1.51046 4.53446 1.5139 5.66755 2.1347 7.04146C3.63081 10.7318 9.2894 16.3011 12.9936 17.8534C13.6771 18.1731 14.3022 18.3334 14.8637 18.3334C15.4131 18.3334 15.9024 18.18 16.3229 17.876C16.6401 17.6932 18.3779 16.2266 18.3323 14.9419C18.3048 14.6144 18.1337 14.287 17.8258 13.9699C17.3572 13.4857 15.6419 12.0884 15.1389 11.9827Z" fill="#74747E"/>
</svg>

    );
}

export default Phone;