import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LeftArrow from '../../assets/svg/LeftArrow';
import './index.scss'
import Offer, { Completed } from './Offer';
import { Cancelled } from './Offer';
import { useDispatch } from 'react-redux';
import { ThreeDots } from 'react-loading-icons';
import { setBidType } from '../../utils/messages/bid';
import { useSelector } from 'react-redux';

function Negotiations(props) {
    return (
        <Routes>
            <Route path='' element={<Index />}/>
        </Routes>
    );
}

export default Negotiations;

function Index(){
    const dispatch=useDispatch()
    const bidding= useSelector(state=>state.bid)
    
    const [loading,setLoading]=useState(false)
    useEffect(()=>setLoading(bidding.activeBids.loading),[bidding])
   const navigate=useNavigate()
  
    const width = window.innerWidth;
    return width < 600 ? (
      <div  className="auth negotiation">
         <div className="top">
                <LeftArrow onClick={() => navigate(-1)} />
         </div>
         <div className="instruction">
             <h3>
                 Negotiation
             </h3>
         </div>
         <div className="category">
             <span onClick={()=>dispatch(setBidType('pending'))}className={bidding?.activeBids.type==='pending' && 'active'}>Pending</span>
             <span onClick={()=>dispatch(setBidType('completed'))} className={bidding?.activeBids.type==='completed' && 'active'}>Completed</span>
             <span onClick={()=>dispatch(setBidType('cancelled'))} className={bidding?.activeBids.type==='cancelled' && 'active'}>Cancelled</span>
         </div>
         <div className="body">
            {
                    (loading ||bidding?.activeBids.loading) && <div  style={{display:'flex',
            justifyContent:'center'}}>< ThreeDots style={{margin:'auto'}}/></div>
            }
                { !bidding?.activeBids.loading&&<>
            {bidding?.activeBids.type==='pending'&& <> {bidding?.activeBids.bids?.map(
                (neg)=>(
                    <Offer key={neg.id} data={neg}/>
                )
            )}

            {
                            !bidding?.activeBids?.bids?.length &&<p style={{textAlign:'center'}}> No pending negotiation</p>
            }
            </>}
            {bidding?.activeBids.type==='cancelled'&&bidding?.activeBids.bids.map(
                (neg)=>(
                    <Cancelled key={neg.id} data={neg}/>
                )
            )}
            {bidding?.activeBids.type==='completed'&&bidding?.activeBids.bids.map(
                (neg)=>(
                    <Completed key={neg.id} data={neg}/>
                )
            )}
            </>}
         </div>
      </div>
  ):(
    <div style={{gap:'0'}} className="auth negotiation">
        
         <div className="instruction">
             <h3>
                 Negotiation
             </h3>
         </div>
         <div className="category">
             <span onClick={()=>dispatch(setBidType('pending'))}className={bidding?.activeBids.type==='pending' && 'active'}>Pending</span>
             <span onClick={()=>dispatch(setBidType('completed'))} className={bidding?.activeBids.type==='completed' && 'active'}>Completed</span>
             <span onClick={()=>dispatch(setBidType('cancelled'))} className={bidding?.activeBids.type==='cancelled' && 'active'}>Cancelled</span>
         </div>
         <div className="body">
            {
                loading&& <div  style={{display:'flex',
            justifyContent:'center'}}>< ThreeDots style={{margin:'auto'}}/></div>
            }
            {!loading&&<>
            {bidding?.activeBids.type==='pending'&&bidding?.activeBids.bids?.map(
                (neg)=>(
                    <Offer key={neg.id} data={neg}/>
                )
            )}
            {bidding?.activeBids.type==='cancelled'&&bidding?.activeBids.bids.map(
                (neg)=>(
                    <Cancelled key={neg.id} data={neg}/>
                )
            )}
            {bidding?.activeBids.type==='completed'&&bidding?.activeBids.bids.map(
                (neg)=>(
                    <Completed key={neg.id} data={neg}/>
                )
            )}
            </>}
         </div>
      </div>
  )
}

