import React, { useState } from 'react'
import './index.scss'
import axios from "axios";

import Button from '../../components/Button'
import camera from './Camera'
// import useAxios from '../../config/useAxios';
import { ToastContainer, toast } from 'react-toast';
import { Base_url } from '../../config/axiosInstance';
import { useNavigate } from 'react-router-dom';
export default function Kyc() {
    const navigate =useNavigate()
   const [obj,setObj]=useState({verificationType:"",id:""})
   const [image,setImage]=useState()
   const [snap,setSnap]=useState(false)

   const verify= async()=>{
        const header = {
           "content-type": "application/json",
           Authorization: `Bearer ${sessionStorage.getItem("token") }`,
       };
   
    const formdata = new FormData()
       formdata.append('selfie', image)
        formdata.append('id',obj.id)
       formdata.append('verificationType', obj.verificationType)

       try {
        
           const { data, status } = await axios({
               method: "post",
               url: `${Base_url}user/kyc/verify`,
               headers: header,
               data: formdata,
           });
        
           toast.success("SuccessFul, redirecting")
            navigate("/home")
      
        
       } catch (error) {
       toast.error("Check your details and try again later")

   }
   }

    return (
        <>
            <ToastContainer position="top-center" delay={'1000'} />
        <div className='auth kyc'>
          <div style={{display:snap?'flex':'none'}} className="image_snap">
            <p> Make sure you are in a lighted area and your picture is clear</p>
                <Button content={image?"Use Image":"Take shot"} onClick={async ()=>{if(image){
                    camera.stopCamera()
                    setSnap(false)
                }
                else{camera.takeSnapshot()
                    const dataURL = camera.canvas.toDataURL('image/png');

                    let file = await fetch(dataURL).then(r => r.blob()).then(blobFile => new File([blobFile], "snapshot.png", { type: "image/png" }))
                 
                    setImage(file)
                }
                }}/>
            </div>
            <div className="logo"><img src="./Logo.png" alt="" /></div>
            <h2>
                Verify Your Identity
            </h2>

            <form htmlFor="">
                <select onChange={(e)=>setObj({...obj,['verificationType']:e.target.value})} name="" id="">
                    <option selected disabled value="">Select Verification Mode</option>

                    <option value="bvn">Bank Verification Number</option>
                    <option value="nin">National Identity Number</option>
                </select>

                <input onChange={(e) => setObj({ ...obj, ['id']: e.target.value })} placeholder='Enter Number' />
            
               

                <Button disabled={image?true:false} content={image?"Snapshot.img":"Take a Selfie"} onClick={(e)=>{
                    e.preventDefault();
                    camera.startCamera()
                    setSnap(true)
                    
                    }}/>
                <Button onClick={(e)=>{
                    e.preventDefault()
                    if(obj.id&&obj.verificationType && image)verify()
                else toast.error("fill all fields")}} content={"Submit"}/>
            </form>
        </div>
        </>
    )
}