import LeftArrow from "../../assets/svg/LeftArrow";
import LoginPic from '../../assets/png/login.png'
import './index.scss'
import OpenEye from "../../assets/svg/openEye";
import ClosedEye from "../../assets/svg/closedEye";
import { useEffect, useState } from "react";
import Button from "../../components/Button";
import { Link, Navigate, useNavigate } from "react-router-dom";
import hands from '../../assets/png/hands.png'
import axios from '../../config/axiosInstance'

import {enterUser} from '../../utils/features/user'
import { useDispatch } from "react-redux";
import AuthHook from "../../hooks/auth";
import useAxios from "../../config/useAxios";



export default function Login(){
      const dispatch=useDispatch()
      const [showPassword,setShowPassword]=useState(false)
      const navigate=useNavigate()
      const [loginParams,setLoginParams]=useState({
          field:'',
          password:''
      })
     
      const {response,error,login,loading}=AuthHook()
     const signIn=(e)=>{
         e.preventDefault()
         login(loginParams)
    }
   
    
    
    const setUp= ()=>{
      dispatch(enterUser(response))
      navigate('/home')
    }
    
   
    useEffect(()=>response?setUp():'',[response])
    const width= window.innerWidth


    // email not verified action
    const [resResponse, resError, fetchData,resLoading] = useAxios()

    const resendOtp = () => {
        fetchData({
            method: 'GET',
            url: `user/auth/re-generate-otp?email=${loginParams.field}`,
            axiosInstance: axios
        })
    }
    useEffect(()=>{
      if(error && error.includes("not verified")){
        // resendOtp()
          sessionStorage.setItem('sentTime', new Date())
          navigate('/verify/' + loginParams.field)
      }
    },[error])
    
    return width<600?(
        <div className="auth login">
        <div  className="top">
          <LeftArrow onClick={() => navigate("/")} />
        </div>
        <div className="instruction">
            <h3>
              Welcome back
             </h3>
            <p>
              It’s a new day and a day to help. Jump right in let’s help the world :)
            </p>
        </div>
        <div class='img'><img src={LoginPic} alt="" /></div>
        <form action="">
            {error && <span style={{margin:'auto',display:'block',textAlign:'center',fontSize:'12px',color:'red'}}>{error}</span>}
            <input type="text" placeholder="Email address or Phone number"name='field' onChange={(e)=>setLoginParams({...loginParams,[e.target.name]:e.target.value})} />
           <div className="carries_svg"> 
                 <input type={showPassword?'text':'password'} placeholder='Password' name='password' onChange={(e)=>setLoginParams({...loginParams,[e.target.name]:e.target.value})}/>
                 <div className="svg">
                 {!showPassword && <span onClick={()=>setShowPassword(!showPassword)}><OpenEye /></span>}
                 {showPassword && <span onClick={()=>setShowPassword(!showPassword)}><ClosedEye/></span>}
                </div>
           </div>
            <Link to={'/reset'} className="forgot"><span>forgot password?</span></Link>
            <Button disabled={!loginParams.field&&!loginParams.password}  onClick={signIn}content={loading?'Please wait':'Login'}/>
        </form>
        <span class='link'>
        Dont have an account? <Link to='/auth'>Register</Link>
        </span>
    </div>
     ):(
        <div className="big_auth">
        <div className="auth login">
       
        <div className="instruction">
            <h3>
              Welcome back
             </h3>
            <p>
              It’s a new day and a day to help. Jump right in let’s help the world :)
            </p>
        </div>
        
        <form action="">
            {error && <span style={{margin:'auto',display:'block',textAlign:'center',fontSize:'12px',color:'red'}}>{error}</span>}
            <input type="text" placeholder="Email address or Phone number"name='field' onChange={(e)=>setLoginParams({...loginParams,[e.target.name]:e.target.value})} />
           <div className="carries_svg"> 
                 <input type={showPassword?'text':'password'} placeholder='Password' name='password' onChange={(e)=>setLoginParams({...loginParams,[e.target.name]:e.target.value})}/>
                 <div className="svg">
                 {!showPassword && <span onClick={()=>setShowPassword(!showPassword)}><OpenEye /></span>}
                 {showPassword && <span onClick={()=>setShowPassword(!showPassword)}><ClosedEye/></span>}
                </div>
           </div>
            <Link to={'/reset'} className="forgot"><div>forgot password?</div></Link>
            <Button disabled={!loginParams.field&&!loginParams.password}  onClick={signIn}content={loading?'Please wait':'Login'}/>
        </form>
        <span class='link'>
        Dont have an account? <Link to='/auth'>Register</Link>
        </span>
    </div>
        
         <div className="image">
             <img src={hands} alt="" />
         </div>
         </div>
     )

  
}