

const camera = function () {
    let width = 0;
    let height = 0;
    let medStream
    const createObjects = function () {

        const snapScreen = document.querySelector('.image_snap')
        
        const video = document.createElement('video');
        video.id = 'video';
        video.width = width;
        video.width = height;
        video.autoplay = true;
       snapScreen.appendChild(video);

        const canvas = document.createElement('canvas');
        canvas.id = 'canvas';
        canvas.width = width;
        canvas.height = height;
       snapScreen.appendChild(canvas);
    }


    return {
        video: null,
        context: null,
        canvas: null,

        startCamera: function (w = 680, h = 580) {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                width = w;
                height = h;

                createObjects();

                this.video = document.getElementById('video');
                this.canvas = document.getElementById('canvas');
                this.context = this.canvas.getContext('2d');


                (function (video) {
                    navigator.mediaDevices.getUserMedia({ video: true }).then(function (stream) {
                        video.srcObject = stream;
                        video.play();
                        medStream = stream
                    });
                })(this.video)

            }
        },

        stopCamera: function () {
            if (medStream) {
                // Get tracks from the media stream and stop them
                medStream.getTracks().forEach(function (track) {
                    track.stop();
                });
                // Clear the stream reference
                medStream = null;
                // Stop video element
                this.video.pause();
                
            }
        },
        takeSnapshot: function () {
            this.context.drawImage(this.video, 0, 0, width, height);
           
            // console.log(this.canvas,this.context);
            this.video.style="display:none"
        }

    }
}();

export default camera;