import { createSlice } from "@reduxjs/toolkit";

export const singleErrand=createSlice({
    name:'errand',
    initialState:{value:{}},
    reducers:{
        loadErrand:(state,action)=>{ 
            state.value=action.payload
        }
    }
})

export const {loadErrand}=singleErrand.actions 
export default singleErrand.reducer