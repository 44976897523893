import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Update from '../../hooks/update';
import useAxios from '../../config/useAxios';
import axios from '../../config/protected'
import { enterUser } from '../../utils/features/user';
import { useDispatch, useSelector } from 'react-redux';
import Protected from '../../config/protected';

function Edit() {
    const [data,setData]=useState({})
    const [password,setPassWord]=useState({})
    const imageRef=useRef(null)
    const {update,loading,error,response}=Update()
     const navigate=useNavigate()
   
    const submit=()=>{
     if(data){
         update(data)
     }else {
        navigate(-1)
     };
    }
    const dispatch=useDispatch()
    useEffect(()=>response?dispatch(enterUser(response)):'',[response])
    const [updateresponse,updateError,fetchData,passWordLoading]=useAxios()

    const updatePassword=()=>{
      
      if(password.newPassword&&password.currentPassword){
      fetchData({
        method:'PATCH',
        url:'user/update-password',
        axiosInstance:Protected(sessionStorage.getItem('token')),
        requestConfig:{
         ...password
        }
      })
    }
    }
    const user=useSelector(state=>state.user)
    // console.log(user);
    useEffect(()=>updateresponse?navigate('/login'):'',[updateresponse])
    return (
        <div className='edit_profile'>
           <form action="" className="details">
              <div className="img">
                  <div className="svg" onClick={()=>imageRef.current.click()}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path fill-rule="evenodd" clip-rule="evenodd" d="M17.44 6.2364C17.48 6.30633 17.55 6.35627 17.64 6.35627C20.04 6.35627 22 8.3141 22 10.7114V16.6448C22 19.0422 20.04 21 17.64 21H6.36C3.95 21 2 19.0422 2 16.6448V10.7114C2 8.3141 3.95 6.35627 6.36 6.35627C6.44 6.35627 6.52 6.31632 6.55 6.2364L6.61 6.11654C6.64448 6.04397 6.67987 5.96943 6.71579 5.89376C6.97161 5.35492 7.25463 4.75879 7.43 4.40844C7.89 3.50943 8.67 3.00999 9.64 3H14.35C15.32 3.00999 16.11 3.50943 16.57 4.40844C16.7275 4.72308 16.9674 5.2299 17.1987 5.71839C17.2464 5.81921 17.2938 5.91924 17.34 6.01665L17.44 6.2364ZM16.71 10.0721C16.71 10.5716 17.11 10.9711 17.61 10.9711C18.11 10.9711 18.52 10.5716 18.52 10.0721C18.52 9.5727 18.11 9.16315 17.61 9.16315C17.11 9.16315 16.71 9.5727 16.71 10.0721ZM10.27 11.6204C10.74 11.1509 11.35 10.9012 12 10.9012C12.65 10.9012 13.26 11.1509 13.72 11.6104C14.18 12.0699 14.43 12.6792 14.43 13.3285C14.42 14.667 13.34 15.7558 12 15.7558C11.35 15.7558 10.74 15.5061 10.28 15.0466C9.82 14.5871 9.57 13.9778 9.57 13.3285V13.3185C9.56 12.6892 9.81 12.0799 10.27 11.6204ZM14.77 16.1054C14.06 16.8147 13.08 17.2542 12 17.2542C10.95 17.2542 9.97 16.8446 9.22 16.1054C8.48 15.3563 8.07 14.3774 8.07 13.3285C8.06 12.2897 8.47 11.3108 9.21 10.5616C9.96 9.81243 10.95 9.40289 12 9.40289C13.05 9.40289 14.04 9.81243 14.78 10.5516C15.52 11.3008 15.93 12.2897 15.93 13.3285C15.92 14.4173 15.48 15.3962 14.77 16.1054Z" fill="white"/>
                  </svg>
                 <input type='file' ref={imageRef} name='avatar' style={{display:'none'}} accept='image/*' onChange={(e)=>{
                  let file=e.target.files
                  setData({...data,[e.target.name]:file[0]})} }/>
                  </div>
               <img src={user.value.avatar} alt="" />    
              </div> 
              <label htmlFor="">
                <span>Full Name</span> 
                <input type="text" readOnly disabled placeholder={user.value.fullName} />   
              </label> 
              <label htmlFor="">
                <span>Email address</span> 
                <input type="email" readOnly disabled placeholder={user.value.email}/>   
              </label> 
              <label htmlFor="">
                <span>Phone Number</span> 
                <input type="tel" name='phoneNumber' placeholder={user.value.phoneNumber} onChange={(e)=>setData({...data,[e.target.name]:e.target.value})}/>   
              </label> 
              <label htmlFor="">
                <span>Username</span> 
            <input type="text" name='username' readOnly disabled placeholder={`@${user.value.username}`} onChange={(e)=>setData({...data,[e.target.name]:e.target.value})} />   
              </label> 
              <Button  onClick={(e)=>{
                e.preventDefault()
                submit()
              }} content={loading?'please wait':'Save'}/>
           </form> 
          
           <div className="change">
               <h1>
                   Change Password
               </h1>
               <form action="">
                {updateError && <span style={{margin:'auto',display:'block',textAlign:'center',fontSize:'12px',color:'red'}}>{updateError}</span>}

                <label htmlFor="">
                    <span>Current Password</span>
                    <input name='currentPassword' type="password" placeholder='Enter current password' onChange={(e)=>setPassWord({...password,[e.target.name]:e.target.value})}/>
                </label>
                <label htmlFor="">
                    <span>New Password</span>
                    <input name='newPassword' type="password" placeholder='Enter new password' onChange={(e)=>setPassWord({...password,[e.target.name]:e.target.value})}/>
                </label>
                
                <Button  content={passWordLoading?'please wait':'Change password'} onClick={
                  (e)=>{e.preventDefault()
                      updatePassword()
                  }}/>
               </form>

           </div>
        </div>
    );
}

export default Edit;