import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import User from "../../assets/png/testImage.png";
import Cancel from "../../assets/svg/Cancel";
import Button from "../../components/Button";
import Protected from "../../config/protected";
import _protected from "../../config/protected";
import useAxios from "../../config/useAxios";
import GetTime from "../../hooks/gettingTIme";
import { setNewBid } from "../../utils/messages/bid";

function Offer({ data }) {
  const user = useSelector((state) => state.user);
  const bidding = useSelector((state) => state.bid);
  const [bidPrice, setBidPrice] = useState();
  const [accepted, setAccepted] = useState(false);
  const [negotiate, setNegotiate] = useState(false);
  const dispatch= useDispatch()

  const [response, error, fetchData, loading] = useAxios();

  const placeBid = () => {
    fetchData({
      method: "POST",
      url: `errands/${data.id}/bid`,
      axiosInstance: Protected(sessionStorage.getItem("token")),
      requestConfig: {
        price: bidPrice,
      },
    });
  };
  
  
  const [success, setSuccess] = useState(false);
  
  const [acceptResponse, acceptError, acceptData, accceptLoading] = useAxios();

 

  const { getDiff } = GetTime();
  const pay = data.user.id === user.value.id ? "Pay" : "recieve";
  const theirpay = data.user.id === user.value.id ? "recieve" : "pay";

  // accepting bid
  const [cancelResponse, cancelError, cancelData, cancelLoading] = useAxios();
  const [completeResponse, completeError, completeData, completeLoading] =
    useAxios();
  const navigate = useNavigate();
  const AcceptBid = () => {
    acceptData({
      method: "POST",
      url: `errands/${data.id}/accept`,
      axiosInstance: Protected(sessionStorage.getItem("token")),
    });
  };
 
  const Canceltr = () => {
    cancelData({
      method: "Patch",
      url: `errands/${data.id}/cancel`,
      axiosInstance: Protected(sessionStorage.getItem("token")),
    });
  };
  const [confirmResponse, confirmError, confirmData, confirmLoading] =
    useAxios();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const EnterPassword = () => {
    confirmData({
      method: "POST",
      url: "user/verify-by-password",
      axiosInstance: Protected(sessionStorage.getItem("token")),
      requestConfig: {
        password: password,
      },
    });
  };
  const Complete = () => {
    if (user.value.id !== data.user.id){ completeData({
      method: "POST" ,
      url: `errands/${data.id}/complete`,
      axiosInstance: Protected(sessionStorage.getItem("token")),
    });}
    else setShowPassword(true)
  };
  useEffect(() => {
    if (confirmResponse) {
      completeData({
        method: user.value.id !== data.user.id ? "POST" : "PATCH",
        url: `errands/${data.id}/confirm-complete`,
        axiosInstance: Protected(sessionStorage.getItem("token")),
        requestConfig: {
          password: password,
        }
      });
    }
  }, [confirmResponse]);

  useEffect(() => {
    if (acceptResponse) setAccepted(true);
    if (cancelResponse) navigate(-1);
  }, [acceptResponse, cancelResponse]);
  useEffect(() => {
    if (completeResponse) setSuccess(true);
  }, [completeResponse]);

  // messaging
  const sendMessage = async () => {

    const userId = await data.biddings[0]?.senderId??data?.ongoingErrand?.helperId
    if (user.value.id === userId) {
      navigate(`/messages/${data.user.id}`);
    } else navigate(`/messages/${userId}`);
  };
  


  useEffect(() => {
    if (response||acceptResponse||completeResponse) dispatch(setNewBid(true));
  }, [response,acceptResponse,completeResponse]);
  return (
    <>
      <div class="offers" onClick={() => setNegotiate(true)}>
        <div className="heading">
          <div className="avatar">
            <img src={data.user.avatar} alt="" />
          </div>
          <div className="name_username">
            <h4>{data.user.fullName}</h4>
            <span>
              @{data.user.username}
              <span class="time">{getDiff(data.createdAt)}</span>
            </span>
          </div>
          <div className="checkin">View</div>
        </div>
        <p className="message">{data.errandNote}</p>
        <div className="current_offer">
          <div className="header">Current Offer:</div>
          <span>
            {data.ongoingErrand
              ? `On Going @ N${data.ongoingErrand.price} by @${data.ongoingErrand?.helper.username})`
              : `${
                  data.biddings &&
                  data.biddings[data.biddings?.length - 1].price
                } NGN by @${
                  data.biddings &&
                  data.biddings[data.biddings?.length - 1]?.sender.username
                })`}
          </span>
        </div>
      </div>
      {negotiate && (
        <>
          <article className=" auth negotiate">
            <div className="top">
              <div className="left">
                <h3>Details</h3>
                <span>View map</span>
              </div>

              <span onClick={() => setNegotiate(false)}>
                <Cancel />
              </span>
            </div>
            <div className="topic">
              @{data.user.username}: {data.errandNote}.
            </div>
            <div className="chats">
              {data.biddings.map((bid) =>
                bid.senderId === user.value.id ? (
                  <YourBid
                    getDiff={getDiff(bid.createdAt)}
                    pay={pay}
                    bid={bid}
                    key={bid.id}
                  />
                ) : (
                  <ThierBid
                    getDiff={getDiff(bid.createdAt)}
                    data={bid.sender}
                    pay={theirpay}
                    bid={bid}
                    key={bid.id}
                  />
                )
              )}
            </div>
            <p style={{ margin: 0, color: "red" }}>{acceptError?.message}</p>
            <p style={{ margin: 0, color: "red" }}>{cancelError?.message}</p>
            <p style={{ margin: 0, color: "red" }}>{completeError?.message}</p>
            <div style={{gap:'16px'}} class="accept_bid">
              {/* this will change based on reult of acceptance to offer accepted or accept */}
              <span
                className={
                  data.ongoingErrand || accepted ? "accepted" : "accept"
                }
                onClick={() => {
                  !data.ongoingErrand && AcceptBid();
                  data.ongoingErrand && Canceltr();
                }}
              >
                {(accepted || data.ongoingErrand) && "Cancel"}
                {!accepted && !data.ongoingErrand && "Accept"}
              </span> 
             {!data.ongoingErrand &&
             <span
                style={{ background:' rgb(225, 199, 199)',color:'red'}}
                className={
                  data.ongoingErrand || accepted ? "accepted" : "accept"
                }
                onClick={() => {
                
                  Canceltr();
                }}
              >
                { "Cancel"}
               
              </span>
              }
              <div onClick={sendMessage} className="checkin">
                Message
              </div>
            </div>
            {!data.ongoingErrand && (
              <>
                {" "}
                <p className="dont_like">Don't Like the offer?</p>
                {error && (
                  <span style={{ color: "red", textAlign: "center" }}>
                    {error.message}
                  </span>
                )}
                <div className="enter_bid">
                  <input
                    onChange={(e) => setBidPrice(e.target.value)}
                    type="text"
                    placeholder="Enter price (NGN)"
                  />
                  <div onClick={placeBid} className="checkin">
                    {loading ? "placing bid" : "place bid"}
                  </div>
                </div>
              </>
            )}
            {data.ongoingErrand && (
              <Button onClick={Complete} content="Confirm errand completed" />
            )}
          </article>
          {showPassword && (
            <div className="alert">
              <article>
                <div className="heading">
                   <div>
                    <Cancel onClick={() => setShowPassword(false)} />
                  </div>
                  <h4>Enter Password to continue</h4>
                  
                </div>
                <div className="message">
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    type={"password"}
                  />
                </div>
                <span className="checkin" onClick={EnterPassword}>
                  Continue
                </span>
              </article>
            </div>
          )}
          {success && (
            <div className="alert">
              <article>
                <div className="heading"><div>
                    <Cancel onClick={() => navigate(-1)} />
                  </div>
                  <h4>Errand Notice</h4>
                  
                </div>
                <div className="message">
                  {user.value.id != data.user.id
                    ? "We will notify the other party"
                    : "Errand successfully completed!!!"}
                </div>
                <span className="checkin" onClick={() => navigate(-1)}>
                  Go home
                </span>
              </article>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Offer;

const ThierBid = ({ getDiff, bid, data, pay }) => {
  return (
    <div className="their_chat">
      <div className="pic">
        <img src={data.avatar} alt="" />
      </div>
      <div className="chat">
        <div className="message">
          @{data.username} offered to {pay} {bid.price} NGN for the help
        </div>
        <div className="time">{getDiff}</div>
      </div>
    </div>
  );
};

const YourBid = ({ getDiff, bid, pay }) => {
  const user = useSelector((state) => state.user);

  return (
    <div className="your_chat">
      <div className="chat">
        <div className="message">
          @you offered to {pay} {bid.price} NGN for the help
        </div>
        <div className="time">{getDiff}</div>
      </div>
      <div className="pic">
        <img src={user.value.avatar} alt="" />
      </div>
    </div>
  );
};

export const Cancelled = ({ data }) => {
  const { getDiff } = GetTime();

  return (
    <div class="offers" onClick={() => {}}>
      <div className="heading">
        <div className="avatar">
          <img src={data.user.avatar} alt="" />
        </div>
        <div className="name_username">
          <h4>{data.user.fullName}</h4>
          <span>
            @{data.user.username}
            <span class="time">{getDiff(data.createdAt)}</span>
          </span>
        </div>
        <div className="checkin"></div>
      </div>
      <p className="message">{data.errandNote}</p>
      <div className="current_offer">
        <div className="header">Cancelled by:</div>
        <span>
          {data.cancelledErrands &&
            data.cancelledErrands[0]?.cancelledBy.username}
        </span>
      </div>
    </div>
  );
};
export const Completed = ({ data }) => {
  const { getDiff } = GetTime();

  return (
    <div class="offers" onClick={() => {}}>
      <div className="heading">
        <div className="avatar">
          <img src={data.user.avatar} alt="" />
        </div>
        <div className="name_username">
          <h4>{data.user.fullName}</h4>
          <span>
            @{data.user.username}
            <span class="time">{getDiff(data.createdAt)}</span>
          </span>
        </div>
        <div className="checkin"></div>
      </div>
      <p className="message">{data.errandNote}</p>
      <div className="current_offer">
        <div className="header">completed by:</div>
        <span>{data.completedErrand?.user.username}</span>
      </div>
    </div>
  );
};
// const Alert=({heading,content})=>{
//     return(
//         <article>
//         <div className="heading">
//             <h4 >
//                 Bid successfully placed
//              </h4>
//              <div onClick={()=>{
//                  setBid(false)
//                  setSuccess(false)
//              }}>
//                  <Cancel />
//              </div>
//          </div>
//          <div className="message">
//          You have successfully placed a bid to run this errand.
//          </div>
//          <span className="checkin">
//                 view transaction
//          </span>
//      </article>
//     )
// }
