import React from 'react';
import LeftArrow from '../../assets/svg/LeftArrow';
import OfferAccepted from '../../assets/svg/OfferAccepted';
import './index.scss'
import Updated from '../../assets/svg/Updated'
import StartedOffer from '../../assets/svg/Started'
import { useNavigate } from 'react-router-dom';



function NotificationPage(props) {
    const navigate =useNavigate()
    return (
        <div class='auth notification'>
            <div className="top">
                <LeftArrow onClick={() => navigate(-1)} />
            </div>
            <div className="instruction">
                <h3>
                    Notification
                </h3>
                {/* <span>
                        Clear
                    </span> */}
            </div>
            <div className="notification_rack">
                {
                   props?.nots?.map(not=>(
                    <>
                           {not.title.includes('accepted') && <Accepted text={not.text} />}
                           {not.title.includes('completed') && <Completed text={not.text} />}
                           {not.title.includes('payment') && <Update text={not.text} />}
                    </>
                  
                   ))
                }
               {/* <Started />
               <Update/>
              
               <Started />
               <Update/>
               <Accepted/>
               <Started />
               <Update/>
               <Accepted/>
               <Started />
               <Update/>
               <Accepted/>
               <Started />
               <Update/>
               <Accepted/> */}
            </div>
        </div>
    );
}

export default NotificationPage;

const Started=()=>{
    return(
        <div className="new_notification">
            <div className="icon">
               <StartedOffer/>
            </div>
            <div className="write_up">
               <h4>
                   Negotiation Started
               </h4>
               <p>
                   <span class='bidder'>
                      @cristiano
                   </span> has started a bid to help with the errand you posted
                    <span> Click to view</span>
               </p>
            </div>
            <div className="angle_brace">
            <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.6665 0.833292L6.33317 5.49996L1.6665 10.1666" stroke="#AEAEB9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

            </div>
        </div>
    )
}

const Update=({text})=>{
    const navigate=useNavigate()
    return(
        <div className="new_notification">
        <div className="icon">
           <Updated />
        </div>
        <div className="write_up">
           <h4>
              Payment Recieved 
           </h4>
           <p>
               <span class='bidder'>
                {text}
               </span> 
                <span style={{ color:'#5D5FEF'} } onClick={()=>navigate('/profile/wallet')}> Click to view</span>
           </p>
        </div>
        <div className="angle_brace">
        <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M1.6665 0.833292L6.33317 5.49996L1.6665 10.1666" stroke="#AEAEB9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        </div>
    </div>
    )
}

const Accepted = (props) => {
    // console.log(props);
    return (
        <div className="new_notification">
            <div className="icon">
                <OfferAccepted />
            </div>
            <div className="write_up">
                <h4>
                    Offer accepted
                </h4>
                <p>
                    <span>{props?.text}</span>
                    {/* <span>Click to pay</span> */}
                </p>
            </div>
            <div className="angle_brace">
                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.6665 0.833292L6.33317 5.49996L1.6665 10.1666" stroke="#AEAEB9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

            </div>
        </div>
    )
}
const Completed = (props) => {
    // console.log(props);
    return (
        <div className="new_notification">
            <div className="icon">
                <OfferAccepted />
            </div>
            <div className="write_up">
                <h4>
                     Update
                </h4>
                <p>
                    <span>{props?.text}</span> {" "}
                    <span style={{ color:'#5D5FEF'}}>Click to pay</span>
                </p>
            </div>
            <div className="angle_brace">
                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.6665 0.833292L6.33317 5.49996L1.6665 10.1666" stroke="#AEAEB9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

            </div>
        </div>
    )
}

