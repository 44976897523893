import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import LeftArrow from "../../assets/svg/LeftArrow";
import Search from "../../assets/svg/Search";
import "./index.scss";
import User from "../../assets/png/testImage.png";
import ChatRoom from "./ChatRoom";
import useAxios from "../../config/useAxios";
import Protected from "../../config/protected";
import { useLayoutEffect } from "react";
import GetTime from "../../hooks/gettingTIme";
import { ThreeDots } from "react-loading-icons";
import { useSelector } from "react-redux";

function Messages(props) {
  const width = window.innerWidth;
  return width < 600 ? (
    <Routes>
      <Route path="" element={<MessageRoom />} />
      <Route path=":userId" element={<ChatRoom />} />
    </Routes>
  ) : (
    <div className="desktop_msg">
      <MessageRoom />
      <Routes>
        <Route path="" element={<Empty />} />
        <Route path=":userId" element={<ChatRoom />} />
      </Routes>
    </div>
  );
}

export default Messages;

const MessageRoom = () => {
  const message = useSelector((state) => state.message);

  const navigate= useNavigate()
  // console.log(message);
  const width = window.innerWidth;
  return width < 600 ? (
    <>
      <div className="auth online message_room">
        <div className="top">
          <LeftArrow onClick={() => navigate(-1)} />
        </div>
        <div className="instruction">
          <h3>Messages</h3>
        </div>
        <form action="">
          <div className="carries_svg">
            <input type="text" placeholder="Search people and messages" />
            <div className="svg">
              <Search />
            </div>
          </div>
        </form>
        {message.activeChats.loading && (
          <ThreeDots stroke="#5D5FEF" style={{ margin: "0 auto" }} />
        )}
        <div className="online_helpers">
          {message?.activeChats.chats &&
            message.activeChats.chats
              ?.slice(0, message.unseenCount)
              .map((user) => <OnlineUser read={true} {...user} />)}

          {message.activeChats.chats?.slice(message.unseenCount).map((user) => (
            <OnlineUser {...user} />
          ))}
        </div>
      </div>
    </>
  ):(
    <>
    <div className="auth online message_room">
    
      <div className="instruction">
        <h3>Messages</h3>
      </div>
      <form action="">
        <div className="carries_svg">
          <input type="text" placeholder="Search people and messages" />
          <div className="svg">
            <Search />
          </div>
        </div>
      </form>
      {message.activeChats.loading && (
        <ThreeDots stroke="#5D5FEF" style={{ margin: "auto" }} />
      )}
      <div className="online_helpers">
        {message?.activeChats.chats &&
          message.activeChats.chats
            ?.slice(0, message.unseenCount)
            .map((user) => <OnlineUser read={true} {...user} />)}

        {message.activeChats.chats?.slice(message.unseenCount).map((user) => (
          <OnlineUser {...user} />
        ))}
      </div>
    </div>
  </>
  );
};

const OnlineUser = ({ avatar, read, id, username, fullName,messages }) => {
  const navigate = useNavigate();
  // const [chats, chatError, getChats, chatLoading] = useAxios();
  const [font, setFont] = useState({ color: "#aeaeb9" });
  useEffect(() => {
    if (read)
      setFont({
        fontWeight: "bolder",
        color: "black",
      });
  }, []);
 
  const { getDiff } = GetTime();

  return (
    <div onClick={() => navigate(`${id}`)} className="online_user">
      <div className="user_detail">
        <div className="avatar">
          <img src={avatar} alt="" />
        </div>
        <div className="name_location">
          <h4>
            {fullName} <span>{username}</span>
          </h4>
          <p className="message_time">
            <span style={{ width: "70%", overflow: "hidden", ...font }}>
              {messages[0].text
                ? messages[0].text
                : messages[0].files[0]
                ? "View file"
                : ""}
            </span>
            <span style={font}>{getDiff(messages[0]?.createdAt)}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const Empty = () => {
  return <div className="empty">

    <h2>
    You don’t have any message selected
    </h2>
    <p>
    Choose one from your existing messages or start a new one
    </p>
  </div>;
};
