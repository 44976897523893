import axios from 'axios'


// export const Base_url='http://178.62.208.203:4000/'
// export const Base_url = 'https://dev.api.ginyverseconnect.com/'
console.log(window.location.host === "app.ginyverse.com");
export const Base_url = window.location.host==="app.ginyverse.com"?'https://api.ginyverse.com/' :'https://api.ginyverse.com/'
export default axios.create({
    baseURL:Base_url,
    headers:{
        'content-type':'application/json',
    }
})