import { createSlice } from "@reduxjs/toolkit";

export const MapSlice= createSlice({
    name:'mapping',
    initialState:{
        lng:'',
        lat:'',
        yourLocation:{lat:'',lng:'',description:''},
        errandLocation:{lat:'',lng:'',description:''}

    
    },
    reducers:{
        changeCenter:(state,action)=>{
            state.lng=action.payload[0]
            state.lat=action.payload[1]
        },
        setFrom:(state,action)=>{
            state.errandLocation.lng=action.payload[0]
            state.errandLocation.lat=action.payload[1]
            state.errandLocation.description=action.payload[2]
        },
        setTo:(state,action)=>{
            state.yourLocation.lng=action.payload[0]
            state.yourLocation.lat=action.payload[1]
            state.yourLocation.description=action.payload[2]
        }
    }
})

export const {changeCenter,setFrom,setTo}= MapSlice.actions
export default MapSlice.reducer