import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cancel from '../../assets/svg/Cancel';
import RadioButton from '../../components/RadioButton';
import Protected from '../../config/protected';
import useAxios from '../../config/useAxios';

function Settings(props) {
    const [setting,settingError,getSetting,loadingSetting]=useAxios()
    const [setadded,setError,set,loading]=useAxios()
    useEffect(()=>getSetting({
        method:'get',
        url:'user/settings',
        axiosInstance:Protected(sessionStorage.getItem('token'))
    }
    ),[setadded])
   
    const data=[{
        title:"Available for help"
        ,writeup:'This means you are available and can be contacted for help',
        icon:<RadioButton active={setting?.availableForHelp} onClick={()=>set({
            method:'patch',url:'user/settings',axiosInstance:Protected(sessionStorage.getItem('token')),requestConfig:{ availableForHelp:!setting.availableForHelp}
        })}/>
    },{
        title:'Enable push notification',
        writeup:'Allow push notifications on your mobile.',
        icon:<RadioButton active={setting?.pushNotification} onClick={()=>set({
            method:'patch',url:'user/settings',axiosInstance:Protected(sessionStorage.getItem('token')),requestConfig:{ pushNotification:!setting.pushNotification}
        })} />

    },{
        title:'Live location  (coming soon)',
        writeup:' Click the toggle to turn location on/off',
        icon:<RadioButton/>

    },
{
    title:'Invite a friend',
    writeup:'Click the share icon to share link to a friend and get them on board',
    icon:<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.125 3.4375C15.125 1.53902 16.664 0 18.5625 0C20.461 0 22 1.53902 22 3.4375C22 5.33598 20.461 6.875 18.5625 6.875C17.519 6.875 16.584 6.41 15.9536 5.6759L6.71685 9.96628C6.8196 10.2925 6.875 10.6398 6.875 11C6.875 11.3602 6.8196 11.7075 6.71685 12.0337L15.9536 16.3241C16.584 15.59 17.519 15.125 18.5625 15.125C20.461 15.125 22 16.664 22 18.5625C22 20.461 20.461 22 18.5625 22C16.664 22 15.125 20.461 15.125 18.5625C15.125 18.2023 15.1804 17.855 15.2831 17.5288L6.04641 13.2384C5.41598 13.9725 4.48104 14.4375 3.4375 14.4375C1.53902 14.4375 0 12.8985 0 11C0 9.10152 1.53902 7.5625 3.4375 7.5625C4.48104 7.5625 5.41598 8.0275 6.04641 8.7616L15.2831 4.47122C15.1804 4.14496 15.125 3.7977 15.125 3.4375Z" fill="#5D5FEF"/>
    </svg>
    
}]

const [logOut,setLogOut]=useState(false)
    return (
        <div class='settings'>
            <div className="instruction">
                <h3>
                    Settings
                </h3>
                <span onClick={()=>setLogOut(true)}>
                    Log out
                </span>
            </div>
            <div className="body">
                 {data.map(data=>(
                    <SettingTab data={data}/> 
                 ))}
            </div>
           {logOut && <div className="log_out">
                    <LogOut onclick={()=>setLogOut(false)}/>
            </div>}
        </div>
    );
}

export default Settings;


const SettingTab=({data})=>{
    return(
        <div className="setting_tab">
            <h4 className="heading">
                {data.title}
            </h4>
            <p className="write_up">
                {data.writeup}
            </p>
            <div className="radio">
                 {data.icon}
            </div>
        </div>
    )
}

const LogOut=({onclick})=>{
    const navigate=useNavigate()
    return(
        <article>
             <div className="heading">
               <span>
                   Log out
               </span>
               <span onClick={onclick} >
                   <Cancel />
               </span>
             </div>
             <p>
             Are you sure you want to log out? 
             </p>

             <div className="confirm" onClick={()=>{
                 window.location.replace('/auth')
                 sessionStorage.clear()
                 }}>
                 Yes,Log out
             </div>
        </article>
    )
}