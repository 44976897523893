import { createSlice } from "@reduxjs/toolkit";

export const userSlice=createSlice({
    name:'errands',
    initialState:{value:{}},
    reducers:{
        enterUser:(state,action)=>{
            if(action.payload){
                 const {id,avatar,fullName,username,phoneNumber,email,verified}=action.payload
            state.value={id,avatar,fullName,username,phoneNumber,email,verified}
            }
           
        }
    }
})

export const {enterUser}=userSlice.actions 
export default userSlice.reducer
