import purpleLogo from '../../assets/png/purpleLogo.png'
import ClosedEye from '../../assets/svg/closedEye'
import OpenEye from '../../assets/svg/openEye'
import Button from '../../components/Button'
import LoginPic from '../../assets/png/login.png'
import { useState } from 'react'
import LeftArrow from '../../assets/svg/LeftArrow'
import { Link } from 'react-router-dom'
import hands from '../../assets/png/hands.png'


export default function NewLogin(){
    const [showPassword,setShowPassword]=useState(false)


    const width= window.innerWidth
    return width<600?(
        <div className="auth login">
              <div className="top">
                {/* <LeftArrow onClick /> */}
            </div>
            <div className="instruction">
                <h3>
                  Welcome to <div class='welcome_logo'><img src={purpleLogo} alt="" /></div>
                 </h3>
                <p>
                Your account has been activated. You can now login with your credentials.
                </p>
            </div>
            <div class='img'><img src={LoginPic} alt="" /></div>
            <form action="">
                <input type="text" placeholder="Email address or Phone number" />
               <div className="carries_svg"> 
                     <input type={showPassword?'text':'password'} placeholder='Password' />
                     <div className="svg">
                     {!showPassword && <span onClick={()=>setShowPassword(!showPassword)}><OpenEye /></span>}
                     {showPassword && <span onClick={()=>setShowPassword(!showPassword)}><ClosedEye/></span>}
                    </div>
               </div>
                <div className="forgot"><span>forgot passowrd?</span></div>
                <Button content='Login'/>
            </form>
            <span class='link'>
            Dont have an account? <Link to='/auth'>Register</Link>
            </span>
        </div>
     ):(
         <div className="big_auth">
         <div className="auth login">
              <div className="top">
                <LeftArrow onClick />
            </div>
            <div className="instruction">
                <h3>
                  Welcome to <div class='welcome_logo'><img src={purpleLogo} alt="" /></div>
                 </h3>
                <p>
                Your account has been activated. You can now login with your credentials.
                </p>
            </div>
            {/* <div class='img'><img src={LoginPic} alt="" /></div> */}
            <form action="">
                <input type="text" placeholder="Email address or Phone number" />
               <div className="carries_svg"> 
                     <input type={showPassword?'text':'password'} placeholder='Password' />
                     <div className="svg">
                     {!showPassword && <span onClick={()=>setShowPassword(!showPassword)}><OpenEye /></span>}
                     {showPassword && <span onClick={()=>setShowPassword(!showPassword)}><ClosedEye/></span>}
                    </div>
               </div>
                <div className="forgot"><span>forgot passowrd?</span></div>
                <Button content='Login'/>
            </form>
            <span class='link'>
            Dont have an account? <Link to='/auth'>Register</Link>
            </span>
        </div>
         <div className="image">
             <img src={hands} alt="" />
         </div>
         </div>
     )
   
}