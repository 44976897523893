import React, { useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LeftArrow from "../../assets/svg/LeftArrow";
import Phone from "../../assets/svg/Phone";
import User from "../../assets/png/testImage.png";
import Gallery from "../../assets/svg/Gallery";
import Send from "../../assets/svg/Send";
import ShareImage from "../../assets/svg/ShareImage";
import ShareErrand from "../../assets/svg/ShareErrand";
import DocumentShare from "../../assets/svg/Document";
import Post from "../GetHelp/Post";
import useAxios from "../../config/useAxios";
import { useEffect } from "react";
import Protected from "../../config/protected";
import { useSelector } from "react-redux";
import GetTime from "../../hooks/gettingTIme";
import { useDispatch } from "react-redux";
import { upDateErrands } from "../../utils/features/errands";
import Cancel from "../../assets/svg/Cancel";
import { Base_url } from "../../config/axiosInstance";
import axios from "axios";
import Mime from "mime/Mime";
import { setOtherParty, setOtherPartyChats } from "../../utils/messages/message";
import {io} from 'socket.io-client'


const socket = io.connect(
  `${Base_url}?token=${sessionStorage.getItem("token")}`,
  { transports: ["websocket"] }
);
function ChatRoom(props) {
  const { userId } = useParams();
  const [share, setShare] = useState(false);
  const [sharePreview, setPreview] = useState(true);
  const navigate = useNavigate();
  const [text, setText] = useState();
  const user = useSelector((state) => state.user);
  const message= useSelector((state)=>state.message)
  const [chat, textError, sendChat, sending] = useAxios();
  const [otherParty, getError, getUser, getting] = useAxios();
  const [seen, seenError, mark, marking] = useAxios();
  const [chatsForm, setChats] = useState([]);
  const errandId = useSelector((state) => state.errand);
  const dispatch = useDispatch();
  const [chats, chatError, getChats, chatLoading] = useAxios();


  
  
  useEffect(() => {
    dispatch(upDateErrands(null));
    mark({
      method: "Patch",
      url: `chat/user/${userId}/seen`,
      axiosInstance: Protected(sessionStorage.getItem("token")),
    });
    getChats({
      method: "get",
      url: `chat/user/${userId}`,
      axiosInstance: Protected(sessionStorage.getItem("token")),
    });
    // getChatDefault()
  }, [chat]);
   
  useEffect(()=>{
    if(chats)dispatch(setOtherPartyChats(chats.results.reverse()))
  },[chats])
  const sendMessage = () => {
    inputRef.current.focus()
    if (inputRef.current.value.trim() !== "") {
      inputRef.current.value = "";
      sendChat({
        method: "Post",
        url: "chat",
        axiosInstance: Protected(sessionStorage.getItem("token")),
        requestConfig: {
          text: text,
          receiverId: userId,
        },
      });
    }
  };
  const types = {
    image: ["png", "jpg", "jpeg", "gif"],
    doc: ["docx", "pdf", "doc"],
  };
  const mime = new Mime(types);

  useEffect(() => {
    if (message.otherPartyChats) setChats(message?.otherPartyChats);
  }, [message.otherPartyChats]);

  useEffect(() => {
    getUser({
      method: "get",
      axiosInstance: Protected(sessionStorage.getItem("token")),
      url: `user/${userId}`,
    });
    dispatch(setOtherParty(userId))
  }, []);

  const inputRef = useRef(null);
  const fileRef = useRef(null);
  const imgRef = useRef(null);
  
  const sendErrand = async () => {
    if (errandId.errandId !== "" || errandId.errandId) {
      try {
        await sendChat({
          method: "Post",
          url: "chat",
          axiosInstance: Protected(sessionStorage.getItem("token")),
          requestConfig: {
            errandId: errandId.errandId.toString(),
            receiverId: userId,
          },
        });
      } catch (error) {}
    }
  };
  useEffect(() => sendErrand(), []);
  const token = sessionStorage.getItem("token");
  const header = {
    "content-type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  
  const sendFiles = async (type, file) => {
    const formData = new FormData();
   

    if (type == "img") {
      formData.append("images", file);
     
    } else formData.append("files", file);
    if (type == "img") {
      try {
        const { data, status } = await axios({
          method: "post",
          url: `${Base_url}files/images`,
          headers: header,
          data: formData,
        });
        const id = await data.map((data) => {
          return data.id;
        });
        sendChat({
          method: "Post",
          url: "chat",
          axiosInstance: Protected(sessionStorage.getItem("token")),
          requestConfig: {
            files: id,
            receiverId: userId,
          },
        });
      } catch (error) {
      } finally {
        setShare(false);
      }
    }
    if (type == "doc") {
      try {
        const { data, status } = await axios({
          method: "post",
          url: `${Base_url}files`,
          headers: header,
          data: formData,
        });
        const id = await data.map((data) => {
          return data.id;
        });
        sendChat({
          method: "Post",
          url: "chat",
          axiosInstance: Protected(sessionStorage.getItem("token")),
          requestConfig: {
            files: id,
            receiverId: userId,
          },
        });
      } catch (error) {
      } finally {
        setShare(false);
      }
    }
  };

  
  useEffect(()=>{
    socket.on('new-message',(data)=>{
      getChats({
        method: "get",
        url: `chat/user/${userId}`,
        axiosInstance: Protected(sessionStorage.getItem("token")),
      })
    })
  },[socket])

  const bottomRef= useRef(null)
  const width = window.innerWidth;
  return width < 600 ? (
    <div className="auth chat_room" onClick={() => setShare(false)}>
      <div className="top">
        <LeftArrow onClick={() => navigate(-1)} />
        <div className="avatar">
          <img src={otherParty?.avatar} alt="" />
        </div>
        <div className="name_username">
          <h4>{otherParty?.fullName}</h4>
          <span>@{otherParty?.username}</span>
        </div>
        <span class="phone">
          <Phone />
        </span>
      </div>
      <div className="chats">
        {chatsForm?.map((chat) => {
          if (chat.text) {
            if (chat.senderId == user.value.id) {
              return (
                <You avatar={user.value.avatar} key={chat.id} data={chat} />
              );
            } else
              return (
                <Them key={chat.id} avatar={otherParty?.avatar} data={chat} />
              );
          }
          if (chat.errand) {
            if (chat.senderId == user.value.id) {
              return (
                <YourErrand
                  avatar={user.value.avatar}
                  key={chat.id}
                  {...chat.errand}
                />
              );
            } else
              return (
                <TheirErrand
                  key={chat.id}
                  username={otherParty?.username}
                  avatar={otherParty?.avatar}
                  {...chat.errand}
                />
              );
          }
          if (chat.files.length > 0) {
            if(chat.senderId==user.value.id){
            if(mime.getType(chat.files[0].src)==='image'){
               return(
                <YourImg avatar={user.value.avatar} key={chat.id} img={chat.files[0].src}/>
               )
            };
            if(mime.getType(chat.files[0].src)==='doc'){

               return(
                <YourDoc avatar={user.value.avatar} key={chat.id} src={chat.files[0].src}/>
               )
            };
          }
           if(chat.senderId== otherParty?.id) {
            if(mime.getType(chat.files[0].src)==='image'){
              return(
               <TheirImg avatar={otherParty?.avatar} key={chat.id} img={chat.files[0].src}/>
              )
           };
            if(mime.getType(chat.files[0].src)==='doc'){
        
              return(
               <TheirDoc avatar={otherParty?.avatar} key={chat.id} src={chat.files[0].src}/>
              )
           };
           }
          }
        })}
        <div ref={bottomRef}></div>
      </div>

      {share && (
        <div onClick={(e) => e.stopPropagation()} class="share_div">
          <div className="container">
            <span onClick={() => fileRef.current.click()}>
              <DocumentShare />
              <input
                ref={fileRef}
                style={{ display: "none" }}
                type="file"
                accept=".pdf,.docx"
                onChange={(e) => {
                  const file = e.target.files;
                  sendFiles("doc", file[0]);
                }}
              />
            </span>
            <span onClick={() => navigate(`/get_help/${otherParty.id}`)}>
              <ShareErrand />
            </span>
            <span onClick={() => imgRef.current.click()}>
              <ShareImage />
              <input
                onChange={(e) => {
                  const file = e.target.files;
                  sendFiles("img", file[0]);
                }}
                ref={imgRef}
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                name=""
                id=""
              />
            </span>
          </div>
        </div>
      )}
      <form onSubmit={(e)=>{
        e.preventDefault(); sendMessage()
        bottomRef.current.scrollIntoView({behaviour:'smooth',start:'block'})
      }
        
        } className="text_space">
        <div className="container">
          <span
            class="svg"
            onClick={(e) => {
              e.stopPropagation();
              setShare(!share);
            }}
          >
            <Gallery />
          </span>
          <input
            ref={inputRef}
            onChange={(e) => setText(e.target.value)}
            type="text"
          />

          <span onClick={sendMessage} className="svg">
            <Send />
          </span>
        </div>
      </form>
    </div>
  ):(
    <div className="auth chat_room" onClick={() => setShare(false)}>
  
    <div className="chats">
      {chatsForm?.map((chat) => {
        if (chat.text) {
          if (chat.senderId == user.value.id) {
            return (
              <You avatar={user.value.avatar} key={chat.id} data={chat} />
            );
          } else
            return (
              <Them key={chat.id} avatar={otherParty?.avatar} data={chat} />
            );
        }
        if (chat.errand) {
          if (chat.senderId == user.value.id) {
            return (
              <YourErrand
                avatar={user.value.avatar}
                key={chat.id}
                {...chat.errand}
              />
            );
          } else
            return (
              <TheirErrand
                key={chat.id}
                username={otherParty?.username}
                avatar={otherParty?.avatar}
                {...chat.errand}
              />
            );
        }
        if (chat.files.length > 0) {
          if(chat.senderId==user.value.id){
          if(mime.getType(chat.files[0].src)==='image'){
             return(
              <YourImg avatar={user.value.avatar} key={chat.id} img={chat.files[0].src}/>
             )
          };
          if(mime.getType(chat.files[0].src)==='doc'){
            // console.log('your doc',chat);
             return(
              <YourDoc avatar={user.value.avatar} key={chat.id} src={chat.files[0].src}/>
             )
          };
        }
         if(chat.senderId== otherParty?.id) {
          if(mime.getType(chat.files[0].src)==='image'){
            return(
             <TheirImg avatar={otherParty?.avatar} key={chat.id} img={chat.files[0].src}/>
            )
         };
          if(mime.getType(chat.files[0].src)==='doc'){
            // console.log('their doc',chat,otherParty);
            return(
             <TheirDoc avatar={otherParty?.avatar} key={chat.id} src={chat.files[0].src}/>
            )
         };
         }
        }
      })}
      <div ref={bottomRef}></div>
    </div>

    {share && (
      <div onClick={(e) => e.stopPropagation()} class="share_div">
        <div className="container">
          <span onClick={() => fileRef.current.click()}>
            <DocumentShare />
            <input
              ref={fileRef}
              style={{ display: "none" }}
              type="file"
              accept=".pdf,.docx"
              onChange={(e) => {
                const file = e.target.files;
                sendFiles("doc", file[0]);
              }}
            />
          </span>
          <span onClick={() => navigate(`/get_help/${otherParty.id}`)}>
            <ShareErrand />
          </span>
          <span onClick={() => imgRef.current.click()}>
            <ShareImage />
            <input
              onChange={(e) => {
                const file = e.target.files;
                sendFiles("img", file[0]);
              }}
              ref={imgRef}
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              name=""
              id=""
            />
          </span>
        </div>
      </div>
    )}
    <form onSubmit={(e)=>{
      e.preventDefault(); sendMessage()
      bottomRef.current.scrollIntoView({behaviour:'smooth',start:'block'})
    }
      
      } className="text_space">
      <div className="container">
        <span
          class="svg"
          onClick={(e) => {
            e.stopPropagation();
            setShare(!share);
          }}
        >
          <Gallery />
        </span>
        <input
          ref={inputRef}
          onChange={(e) => setText(e.target.value)}
          type="text"
        />

        <span onClick={sendMessage} className="svg">
          <Send />
        </span>
      </div>
    </form>
  </div>
  );
}

export default ChatRoom;
const { getDiff } = GetTime();
const You = ({ data, avatar }) => {
  //   const user = useSelector((state) => state.user);
  return (
    <div className="your_chat">
      <div className="chat">
        <div className="message">{data?.text}</div>
        <div className="time">{getDiff(data.createdAt)}</div>
      </div>
      <div className="pic">
        <img src={avatar} alt="" />
      </div>
    </div>
  );
};
const Them = ({ data, avatar }) => {
  const { getDiff } = GetTime();

  return (
    <div className="their_chat">
      <div className="pic">
        <img src={avatar} alt="" />
      </div>
      <div className="chat">
        <div className="message">{data?.text}</div>
        <div className="time">{getDiff(data.createdAt)}</div>
      </div>
    </div>
  );
};
const TheirErrand = ({ errandNote, avatar, username, ...props }) => {
  const [bid, setBid] = useState(false);
  const [response, error, fetchData, loading] = useAxios();
  const [bidPrice, setBidPrice] = useState();
  const placeBid = () => {
    fetchData({
      method: "POST",
      url: `errands/${props.id}/bid`,
      axiosInstance: Protected(sessionStorage.getItem("token")),
      requestConfig: {
        price: bidPrice,
      },
    });
  };
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (response) setSuccess(true);
  }, [response]);
  return (
    <>
      {bid && (
        <div className="bid">
          {!success && (
            <div className="bid_details">
              <div className="bid_top">
                <span>
                  <h4>Details</h4>
                  <span className="view_map">View map</span>
                </span>
                <span onClick={() => setBid(false)} className="cancel">
                  <Cancel />
                </span>
              </div>
              <div className="errand_description">
                <p class="description">
                  <span class="tag">{`@${username} `}</span>
                  {errandNote}
                </p>
                <p class="description">
                  <span className="tag">{`@${username}`}</span> offers to pay{" "}
                  {props.innitialOffer} NGN. If you are not okay with the offer,
                  enter your price to negotiate and place a bid.
                </p>
                <p class="description">
                  If you are okay with the offer, just place a bid.
                </p>
              </div>
              <div className="enter_price">
                <h4>Enter your price</h4>
                <span>
                  <input
                    type="tel"
                    placeholder="Enter price"
                    onChange={(e) => setBidPrice(e.target.value)}
                  />
                  <span
                    onClick={() => (bidPrice ? placeBid() : "")}
                    class="checkin"
                  >
                    Place bid
                  </span>
                </span>
              </div>
            </div>
          )}
          {success && (
            <article>
              <div className="heading">
                <h4>Bid successfully placed</h4>
                <div
                  onClick={() => {
                    setBid(false);
                    setSuccess(false);
                  }}
                >
                  <Cancel />
                </div>
              </div>
              <div className="message">
                You have successfully placed a bid to run this errand.
              </div>
              <span className="checkin">view transaction</span>
            </article>
          )}
        </div>
      )}
      <div className="their_chat">
        <div className="pic">
          <img src={avatar} alt="" />
        </div>
        <div className="your_errand">
          <div className="errand_note">{errandNote}</div>
          <div className="view" onClick={() => setBid(true)}>
            View
          </div>
        </div>
      </div>
    </>
  );
};
const YourErrand = ({ errandNote, avatar }) => {
  return (
    <div className="your_chat">
      <div className="your_errand">
        <div className="errand_note">{errandNote}</div>
        <div className="view">View</div>
      </div>
      <div className="pic">
        <img src={avatar} alt="" />
      </div>
    </div>
  );
};
const YourDoc = ({ src, avatar }) => {
  const fileRef= useRef(null)
  return (
    <div className="your_chat">
      <div className="your_errand">
        <div className="errand_note">{src.replace('https://ginyverseconnect.fra1.digitaloceanspaces.com/doc/','')}</div>
        <div onClick={()=>fileRef.current.click()} className="view">View</div>
        <a  href={src} style={{display:'none'}} ref={fileRef}></a>
      </div>
      <div className="pic">
        <img src={avatar} alt="" />
      </div>
    </div>
  );
};
const TheirDoc = ({ src, avatar }) => {
  const fileRef= useRef(null)
  return (
    <div className="their_chat">
      <div className="pic">
        <img src={avatar} alt="" />
      </div>
      <div className="your_errand">
        <div className="errand_note">{src.replace('https://ginyverseconnect.fra1.digitaloceanspaces.com/doc/','')}</div>
        <div onClick={()=>fileRef.current.click()} className="view">View</div>
        <a  href={src} style={{display:'none'}} ref={fileRef}></a>
      </div>
      
    </div>
  );
};
const YourImg = ({ img, avatar }) => {
  const fileRef= useRef(null)

  return (
    <div className="your_chat">
      <div onClick={()=>fileRef.current.click()} style={{width:'90%',}} className="sent_img">
        <img  style={{width:'100%'}} src={img} alt="" />
        <a  href={img} style={{display:'none'}} ref={fileRef}></a>

      </div>
      <div className="pic">
        <img src={avatar} alt="" />
      </div>
    </div>
  );
};
const TheirImg = ({ img, avatar }) => {
  const fileRef= useRef(null)

  return (
    <div className="your_chat">
      <div className="pic">
        <img src={avatar} alt="" />
      </div>
      <div onClick={()=>fileRef.current.click()} style={{width:'90%',}}  className="sent_img">
        <img  style={{width:'100%',}} src={img} alt="" />
        <a  href={img} style={{display:'none'}} ref={fileRef}></a>

      </div>
    </div>
  );
};
