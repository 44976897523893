import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import React, { useLayoutEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LeftArrow from "../../assets/svg/LeftArrow";
import axiosInstance from "../../config/axiosInstance";
import Protected from "../../config/protected";
import useAxios from "../../config/useAxios";

function ErrandMap(props) {
    const [lib,setLib]=useState()
    const set=useMemo(()=>setLib(["places"]),[])
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDPOaMOWb5dpDt-GFNAdU1C-eNdC8UE6vs",
    libraries: lib,
  });

  const { errandId } = useParams();

  const [errand, error, fetchErrand, loading] = useAxios();
  const [pan,setPan]=useState(/**@type google.maps.Map */(null))

  useEffect(
    () =>
      fetchErrand({
        method: "Get",
        url: `errands/${errandId}`,
        axiosInstance: Protected(sessionStorage.getItem("token")),
      }),
    []
  );
 const center =useSelector(state=>state.map)
 const navigate=useNavigate()
  // console.log(errand);
  
  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  return (
    <div className="auth">
      <div className="top">
        <LeftArrow onClick={() => navigate(-1)} />
      </div>
      <div className="instruction">
        <div class="instruction_top">
          <h3>Errand Location</h3>
        </div>
        <div className="mapping">
        <GoogleMap
            center={{
              lat: center?.lat,
              lng: center?.lng,
            }}
            zoom={10}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            options={{
              mapTypeControl: false,
            }}
            onLoad={(map) => setPan(map)}
          >
            <Marker
              position={{
                lat: center?.lat,
                lng: center?.lng,
              }}
            />
           
            {
                errand && <Marker label={'To'} position={{lat:errand.currentLocation?.coordinates[1],lng:errand.currentLocation?.coordinates[0]}}/>
            }
            {
              errand && <Marker label={'From'} position={{lat:errand.errandLocation?.coordinates[1],lng:errand.errandLocation?.coordinates[0]}}/>
            }
             
          </GoogleMap> 
        </div>
      </div>
    </div>
  );
}

export default ErrandMap;
