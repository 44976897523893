import React, { useState } from "react";
import PaystackPop from "@paystack/inline-js";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
// import ClosedEye from "../../assets/svg/closedEye";
// import OpenEye from "../../assets/svg/openEye";
import {useNavigate} from 'react-router-dom'

function Fund(props) {
  const paystack = new PaystackPop();
  const key = window.location.host === "app.ginyverse.com" ? process.env.REACT_APP_PSKEY : process.env.REACT_APP_PSTKEY;
  const user=useSelector(state=>state.user)
  const navigate=useNavigate()
  const [amount,setAmount]=useState()

  const fundWallet = (e) => {
    e.preventDefault()
    if(amount)
    paystack.newTransaction({
      amount: amount *100,
      email: user.value.email,
      key: key,
       onSuccess:()=>navigate(-1)
      
    
    });
  };
  return (
    <div className="fund auth">
      <div className="instruction">
        <h3>Fund Wallet</h3>
      </div>
      <form action="">
                {/* {error && <span style={{margin:'auto',display:'block',textAlign:'center',fontSize:'12px',color:'red'}}>{error}</span>} */}
                <input type="number" placeholder="Enter Amount"name='field' onChange={(e)=>{setAmount(e.target.value)}} />
               {/* <div className="carries_svg"> 
                     <input type={showPassword?'text':'password'} placeholder='Password' name='password'/>
                     <div className="svg">
                     {!showPassword && <span onClick={()=>setShowPassword(!showPassword)}><OpenEye /></span>}
                     {showPassword && <span onClick={()=>setShowPassword(!showPassword)}><ClosedEye/></span>}
                    </div>
               </div> */}
                {/* <Link to={'/reset'} className="forgot"><span>forgot passowrd?</span></Link> */}
                <Button onClick={fundWallet} content={'Continue'} />
            </form>
    </div>
  );
}

export default Fund;
