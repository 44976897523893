import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import useAxios from '../../config/useAxios';
import './index.scss'
import Location from './Location';
import Post from './Post';
import axios from '../../config/protected'
import LoadLocation from './Location';


function GetHelp(props) {
    const navigate=useNavigate()
   
    

   
    return (
      
        <div className='auth get_help'>
           <Routes>
              <Route path='' element={<Post head={'Post an errand'}
              button={'Post errand'}
           
            requestParams={{
            
            method:'POST',
            url:'errands',
            axiosInstance:axios(sessionStorage.getItem('token'))
            }}
              />}/>
              <Route path=':userId' element={<Post head={'Share an errand'}
              button={'Share errand'}
           
            requestParams={{
            
            method:'POST',
            url:'errands',
            axiosInstance:axios(sessionStorage.getItem('token'))
            }}
              />}/>
              <Route path='location' element={<LoadLocation />} />
           </Routes>
        </div>
    );
}

export default GetHelp;